.danny {
  display: flex;
  flex: 2;
}
.danny img {
  display: fixed;
}
.danny .tro {
  margin-top: -24%;
}
.workP {
  width: 68%;
  margin: 0px auto;
}
.danny .tro .lp {
  display: block;
  margin-top: 20%;
}
// .danny .tro .lp h2{
//     color: red;
//     font-size: 42px;
//     font-weight: bold;
// }
.workHead h2 {
  display: flex;
  justify-content: center;
  color: #8cb649;
  font-size: 42px;
  font-weight: bold;
  align-items: center;
}
.danny .tro .lp p {
  color: #9c99a1;
  padding-top: 4%;
  font-weight: 600;
}
.danny .no {
  display: block;
  margin-right: 4%;
}

.danny .no .ll p {
  justify-content: center;
  color: #9c99a1;
  align-items: center;
}

.danny .no .zz {
  margin-top: 0%;
}

.lp .ptext {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0% auto;
}

.juice {
  margin-left: 35%;
}

.imgwork {
  margin-left: 40%;
}

.imgwork1 {
  margin-left: 35%;
}
@media screen and (max-width: 430px) {
  .danny {
    // display:block;
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    padding-bottom: 0;
    height: 100vh;
  }
  .workHead h2 {
    margin-top: 70%;

    font-size: 30px;
    text-align: center;
  }
  .lp .ptext {
    color: rgb(20, 13, 13);
    margin-top: -51%;
    margin-left: 10%;
  }
  .danny .hd {
    //  position: absolute;
    //  z-index: -639654;
    width: 45%;
    height: 18vh;
  }
  .danny .tro {
    margin-top: 2%;
  }
  .danny .no .zz {
    display: block;
    border-color: white;
  }

  .workP {
    width: 95%;
  }

  .danny .no .ll p {
    display: flex;
    justify-content: center;
    padding-left: 4%;
    color: rgb(20, 13, 13);
  }

  .juice {
    margin-left: 42%;
  }

  .imgwork {
    margin-left: 45%;
  }

  .imgwork1 {
    margin-left: 42%;
  }
  .danny .tro .lp h2 {
    padding-left: 4%;
  }
  .danny .tro .lp p {
    padding-left: 4%;
    color: rgb(0, 0, 0);
  }

  .danny .no .ll {
    margin-top: 10%;
  }
}
