 
.searchheight1{
    margin: 10vmin 0;
}
.searchtxt1{
    text-align: center;
    margin: 10px 0;
    font-weight: 600;
    font-size: 4vmin;
    color: #444;
}
.conttainerr1{
    margin-bottom: 10vmin;
    width: 1200px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
     
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    grid-gap: 10px; */
    padding: 15px;
    box-sizing: border-box;
}
.conttainerr1 .boxx1{
    position: relative;
    background: #fff;
    /* padding: 20px 40px 40px; */
    text-align: center;
    overflow: hidden;
    border: 2px;
    margin-right: 10px;
}
.conttainerr1 .boxx1 p{
    font-size: 2.4vmin;
    text-align: left;
    line-height: 30px;
    color: #555;
    font-weight: 400;
}

@media screen and (max-width: 430px) {
    .conttainerr1{
         display: flex;
         flex-direction: column;
         width: 400px;
         margin: 1px auto;
    }
    .conttainerr1 .boxx1{
        margin:1px auto;
    }
    .conttainerr1 .boxx1 p{
        font-size: 3.5vmin;
       
        font-weight: 400;
    }
  }
  