@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}
.account21{
    display: grid;
    grid-template-columns: 20% auto;
    height: 100%;
    margin-top: 80px;
  }
  .account21 .left{
    background-color: blue;
    text-align: center;
    align-items: center;
    padding: 10% 0%;
  
  }
  .account21 .left h3{
    margin-top: 10%;
    font-size: 5vmin;
    color: white;
    
  }
  .account21 .left a{
    display: inline-block;
  background: rgb(255, 255, 255);
  font-size: 3vmin;
  color: black;
  cursor: pointer;
  width: 34vmin;
  box-shadow: 0 1px 3px 1px #888888;
  text-align: center;
  padding: 3.5% 0;
  margin-top: 8%;
  margin-left: 10px;
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease;
  }
.profilebody21{
  height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.container21{
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container21 .titlee21{
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
.container21 .titlee21::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.conttent21 form .userdetails21{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .userdetails21 .inputbox21{
  margin-bottom: 15px;
  width: calc(100% / 2 - 20px);
}
form .inputbox21 span.details21{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.userdetails21 .inputbox21 input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.userdetails21 .inputbox21 input:focus,
.userdetails21 .inputbox21 input:valid{
  border-color: #9b59b6;
}
 form .genderdetails21 .gendertitle21{
  font-size: 20px;
  font-weight: 500;
 }
 form .category21{
   display: flex;
   width: 80%;
   margin: 14px 0 ;
   justify-content: space-between;
 }
 form .category21 label{
   display: flex;
   align-items: center;
   cursor: pointer;
 }
 form .category21 label .dot{
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #d9d9d9;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
 #dot-1:checked ~ .category21 label .one,
 #dot-2:checked ~ .category21 label .two,
 #dot-3:checked ~ .category21 label .three{
   background: #9b59b6;
   border-color: #d9d9d9;
 }
 form input[type="radio"]{
   display: none;
 }
 form .button21{
   height: 45px;
   margin: 35px 0
 }
 form .button21 input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   background: linear-gradient(135deg, #71b7e6, #9b59b6);
 }
 form .button21 input:hover{
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
  }
 @media(max-width: 584px){
 .container21{
  max-width: 100%;
}
form .userdetails21 .inputbox21{
    margin-bottom: 15px;
    width: 100%;
  }
  form .category21{
    width: 100%;
  }
  .conttent21 form .userdetails21{
    max-height: 300px;
    overflow-y: scroll;
  }
  .userdetails21::-webkit-scrollbar{
    width: 5px;
  }
  }
  @media(max-width: 459px){
  .container21 .conttent21 .category21{
    flex-direction: column;
  }
}
@media(max-width: 584px){
    .account21{
      grid-template-columns: none;
     
    }
  }