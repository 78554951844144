.cardcont {
  display: flex;
}
.cardit {
  display: flex;
}
.itemBody {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.makeStyles-grid-4 {
  padding: 0 15px !important;
  margin: 10px auto;
}
/* .rowCard{
    margin: 10px;
} */
.itemTitle {
  display: flex;
  flex-direction: column;
}

.removeCart1 {
  cursor: pointer;
  color: red;
  margin: 2%;
}
.titCon h4 {
  margin-left: 20px;
}
.titCon h6 {
  margin-left: 20px;
}
.titCon p {
  margin-left: 20px;
}
.titCon {
  display: flex;
  flex-direction: column;
}
.itemInc {
  margin-right: -1000px;
}
.itemBut {
  background-color: red !important;
}

@media screen and (max-width: 1115px) {
  #itemRow {
    width: 75em;
  }

  #detail {
    width: 900px;
  }
  .itemInc {
    margin-right: -100px;
  }
}

@media screen and (max-width: 700px) {
  #itemRow {
    width: 40em;
  }
  #cardimg {
    min-width: 100px;
  }
  #detail {
    width: 400px;
    font-size: 10px;
  }
  #btnn {
    margin-right: -100px;
  }
}

@media screen and (max-width: 430px) {
  .itemInc {
    margin-right: 10px;
  }
  .itemBody {
    display: flex;

    flex-direction: column;
  }
  /* .cardcont{
        display: flex;
    }
    .cardit{
        display: flex;
         
    } */

  #cardimg {
    min-width: 150px;
  }

  #btnn {
    margin-right: unset;
  }

  #detail {
    width: unset;
    font-size: unset;
  }

  #itemRow {
    width: unset;
  }
}
