

.title1{
    text-align: center;
    justify-content: center;
}
.head69{
    background-color: #f7f7f7;
     height:110vh ;
     width:100%;
     padding-bottom: 2%;
}

.dummy3{
    background-color: #f7f7f7;
    padding: 1rem;
    margin: 1.2rem;
    margin-bottom: 0;
    justify-content: center;
}

.group{
    padding-top: 2%;
    padding-bottom: 1%;
}

.ch{
width: 20%!important;
justify-content: center;
align-items: center;
border-radius: 24px!important;
color : white !important;
}

.last69{
    align-items: center;
    justify-content: center;
    margin-bottom: -1;
    margin-left: 40%;
    padding-top: 5%;
    margin-top:8%;
}
.bar p{
    font-size: 12px;
    padding:1%;
}

@media screen and (max-width: 430px){
   .head69 .main7 .group{
       display:block;
       width:100%;
    }
    .head69{
        padding: 2rem;
        width:100%;
        height:160vh;
    }
    .head69 .main7 .group .dummy3{
        padding-top:3rem;
    }
    .head69 .main7 .title1 h1{
        display:inline;
    }
    .last69{
        align-items: center;
        justify-content: center;
        margin-bottom: -1;
        margin-left: 32%;
        padding-top: 5%;
        margin-top:8%;
    }
}