.join1 {
    padding: 4%;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.title {
    padding: 2%;
    font-weight: bold;
    font-size: 2.2vw;
    margin-left: -50vw;
}

.video1{
    margin-top: -5rem;
}
.title {
    align-items: center;
    padding: 2%;
}

@media screen and (max-width: 430px) {
    .join1 {
        padding: 0%;
        margin: 0%;
        margin-top: 0px;
        z-index: 0;
    }
    .join1 .row {
        display: block;
    }
    .join1 .title {
        font-weight: bold;
        font-size: 5.2vw;
        margin-left: 5vw;
    }
    .join1 h2 {
        font-size: 34px;
    }
    .join1 p {
        font-size: 16px;
        padding: 1rem;
    }
    .join1 .row .work {
        padding: 2rem;
    }
    .join1 .row .told {
        padding: 2rem;
    }
}