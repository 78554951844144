#a {
  list-style-type: none;
  text-decoration: none;
}
.row1 {
  list-style-type: none;
  text-decoration: none;
}

.footImg2 {
  width: 20%;
  margin: 5px;
}

.footImg1 {
  width: 30%;
  margin: 5px;
}

.footImg3 {
  width: 25%;
  margin: 5px;
}

.footer-social {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.imgSocialFoot {
  cursor: pointer;
}
.yelpFoot {
  margin-left: -9%;
}

#sociallogo {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .footImg2 {
    width: 15%;
    margin: 5px;
  }

  .footImg1 {
    width: 25%;
    margin: 5px;
  }

  .footImg3 {
    width: 22%;
    margin: 5px;
  }
  #sociallogo {
    width: 50%;
    margin: 10px;
  }
}
