.join {
    padding: 4%;
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
}

.title p {
    text-align: center;
    justify-content: center;
}

.bg {
    padding: 1em;
}

.centered1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.align-items-center {
    font-size: 1.2vw;
    margin-right: 1.4vw;
    color: #808080;
}

@media screen and (max-width: 430px) {
    .join {
        margin-top: 64%;
    }
}

@media screen and (max-width: 430px) {
    .jod p {
        font-size: 16px;
        display: block;
    }
    .jod h2 {
        font-size: 34px;
    }
    .join {
        margin-bottom: 0px;
    }
    .align-items-center {
        
        margin-right: 8.4vw;
         
    }
}