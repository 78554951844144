.refer-form {
  width: 80% !important;
}

.ss-parent {
  /* margin-bottom: -240px !important; */
  z-index: 1 !important;
  width: 100%;
  margin: 18px auto;
  background-color: rgb(255, 255, 255);
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 2.4em;
  font-family: "Work Sans";
}

.ss-heading {
  font-size: 34px;
  font-weight: bold;
  padding: 14px 0px;
}
.ss-sub-heading {
  font-size: 22px;
  font-weight: bold;
  padding: 4px 0px;
}

.ss-sub-text {
  padding: 14px 0px;
  color: #7e89a3;
}

.refer-ss-section {
  margin-bottom: -216px !important;
}

.refer-form .refer-form-addon-flag {
  display: flex !important;
  align-items: center !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.refer-form .refer-form-flag {
  width: 26px;
  height: 20px;
  margin-right: 8px;
}

.refer-form .refer-form-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: none;
  border-left: none;
}
.refer-form input {
  color: #000 !important;
}

.refer-form .refer-form-addon-button {
  display: flex;
  align-items: center;
  border-radius: 0.25rem 0px 0px 0.25rem;
  border: none;
}
.refer-form .refer-form-button {
  background-color: rgb(16, 16, 16);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  padding: 0px 1.6em;
  height: 100%;
}

.appImg{
  border-radius: 15px;
}
/* mobile screen */
@media only screen and (max-width: 768px) {
  .ss-parent {
    padding: 1em;
  }
  .ss-parent .ss-heading {
    font-size: 26px !important;
  }
  .ss-parent .ss-sub-text {
    font-size: 16px !important;
  }

  .store-icons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }
  .store-icons img {
    margin: -3% 5px !important;
  }
  .ss-section {
    margin-top: 20px !important;
  }
  .ss-section img {
    width: 180px !important;
    margin: auto 5px !important;
  }
  .refer-ss-section {
    margin-top: 1em;
    margin-bottom: -216px !important;
  }
  .refer-ss-section #refer-ss {
    width: 320px !important;
  }

  .refer-form {
    width: 100% !important;
    font-size: 0.8em !important;
  }
  .refer-form .refer-form-button {
    padding: 0px 1em;
    height: 100%;
  }

 
}
