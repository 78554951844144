.typography {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  color: white;
}

.video {
  margin-top: 10.9vw;
}

.welcome {
  margin-bottom: 50px;
}
.chicago {
  margin-bottom: 50px;
}
.dry {
  font-size: 1.5vw;
}
.front {
  margin-top: 10px;
  width: 50% !important;
  height: inherit !important;
  border-radius: 0.25em !important;
}

.ripple {
  font-size: 12px;
}
.ok .btn-success {
  color: white !important;
  background-color: #8cb649;
  border: none;
}
.ok .btn-success:hover {
  background-color: rgb(92, 172, 238) !important;
}
@media screen and (max-width: 430px) {
  .welcome {
    margin-bottom: 5px;
    font-size: 24px;
    display: none;
  }
  .video12 {
    margin-top: 80px;
  }

  .typography {
    position: absolute;
    top: 25%;
    left: 50%;
  }
  .chicago {
    margin-bottom: 25px;
    font-size: 26px;
    display: none;
  }

  .dry {
    display: none;
  }

  .ok {
    margin: 0;
  }
  .front {
    margin-top: 10px;
    width: 80% !important;
    height: inherit !important;
    border-radius: 0.25em !important;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
  .ripple {
    font-size: 2px;
  }
}
