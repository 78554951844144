.special {
    background-color: #6D9FB2;
    padding: 2vw;
    color: white;
    margin-top: 5vw;
    
}

.p5{
    display: flex;
    align-items: center;
    justify-content: center;
}

.h1 {
    display: none;
}

@media screen and (max-width: 430px) {
    .special{
        margin-top: 30vw;
    }
    .h1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .p5{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.3vw;
    }
}