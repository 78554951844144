.catBody {
  display: flex;
  justify-content: space-between;
}

.catTitle {
  display: flex;
  flex-direction: row;
}

.catTitle h4 {
  margin-left: 20px;
}

.btn-success {
  background-color: rgb(140, 182, 74);
}
