.danny788 {
  display: flex;
  flex: 2;
}

.zz11 {
  margin: 4% auto;
}
.danny778 img {
  display: fixed;
}
.danny788 .tro788 {
  margin-top: -15%;
}
.workP788 {
  width: 68%;
  margin: 0px auto;
}
.danny788 .tro788 .lp788 {
  display: block;
  margin-top: 20%;
}
// .danny .tro .lp h2{
//     color: red;
//     font-size: 42px;
//     font-weight: bold;
// }
.workHead788 h2 {
  display: flex;
  justify-content: center;
  color: #8cb649;
  font-size: 42px;
  font-weight: bold;
  align-items: center;
}
.danny788 .tro788 .lp788 p {
  color: #9c99a1;
  padding-top: 4%;
  font-weight: 600;
}
.danny788 .no788 {
  display: block;
  margin-right: 4%;
}

.danny788 .no788 .ll788 p {
  justify-content: center;
  color: #9c99a1;
  align-items: center;
}

.danny788 .no788 .zz778 {
  margin-top: 0%;
}

.lp788 .ptext788 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hd788 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 0% auto;
}

.juice {
  margin-left: 35%;
}

.imgwork778 {
  margin-left: 40%;
}

.text-center788 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
.imgwork1788 {
  margin-left: 35%;
}
@media screen and (max-width: 430px) {
  .danny788 {
    // display:block;
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    padding-bottom: 0;
    height: 460vh;
  }
  .zz11 {
    display: flex;
    flex-direction: column;
  }
  .workHead788 h2 {
    margin-top: 70%;

    font-size: 30px;
    text-align: center;
  }
  .lp788 .ptext788 {
    color: rgb(20, 13, 13);
    margin-top: -51%;
  }
  .danny788 .hd788 {
    //  position: absolute;
    //  z-index: -639654;
    width: 45%;
    height: 18vh;
  }
  .danny788 .tro788 {
    margin-top: -48%;
  }
  .danny788 .no788 .zz788 {
    display: block;
    border-color: white;
  }

  .workP788 {
    width: 95%;
  }

  .danny788 .no788 .ll788 p {
    display: flex;

    justify-content: center;
    padding-left: 4%;
    color: rgb(20, 13, 13);
  }

  .juice {
    margin-left: 42%;
  }

  .imgwork788 {
    margin-left: 45%;
  }

  .imgwork1788 {
    margin-left: 42%;
  }
  .danny788 .tro788 .lp788 h2 {
    padding-left: 4%;
  }
  .danny788 .tro788 .lp788 p {
    padding-left: 4%;
    color: rgb(0, 0, 0);
  }

  .danny788 .no788 .ll788 {
    margin-top: 10%;
    margin-left: 3%;
  }
}
