/* *{
    box-sizing: border-box;
}

.colored-box{
    background: rgb(71, 212, 255);
}
.colored-box h1{
    text-align: center;
    font-size: 6vmin;
    font-weight: 600;
}
.colored-box p{
    text-align: center;
    font-size: 2.8vmin;
    color: #fff;
}

.gallery img{
    width: 100%;
    height: auto;
}

.responsive{
    background: rgb(71, 212, 255);
    padding: 0 6px;
    float: left;
    width: 25%;
}
@media only screen and (max-width: 700px){
    .responsive{
        width: 50%;
        margin: 6px 0;
    }
}
@media only screen and (max-width: 500px){
    .responsive{
        width: 100%;
        
    }
} */

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    min-height: 100vh;
    overflow-x: hidden;
}
.main{
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px,300px));
    column-gap: 10px;
    row-gap: 10px;
}
.main img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    object-position: center;
} */
 
.gallery-back h1{
    padding: 3% 0;
    text-align: center;
    font-size: 6vmin;
    font-weight: 600;
    color: #fff;
    
}
.gallery-back p{
    text-align: center;
    font-size: 2.8vmin;
    color: #fff;
    padding-bottom: 2%;
}
.gallery-back{
    background: rgb(71, 212, 255);
    margin: 0.2%;
    margin-top: 80px;
}
.main1{
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 10px; */

}
.main1 img{
    padding: 10px;
}