.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: (0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-inner1 {
  position: relative;
  padding: 0px;
  width: 100%;
  max-width: 300px;
  margin-left: -8%;
  // background-color: rgb(245, 239, 239);
  border-radius: 30px;
}
.popup-inner1:hover {
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
}

.popImg222 {
  border-radius: 8px;
}
.popup-inner1 .close-btn {
  position: absolute;
  top: 16px;
  right: -27%;
  border-radius: 5px;
  width: 12%;
  border: none;
}

.popup-inner1 .okayBtn {
  position: absolute;
  bottom: 18px;
  right: 0;
  border-radius: 5px;
  width: 23%;
  height: 10%;
}

.popup-inner1 .okayBtn:hover {
  border: 1px solid crimson;
  color: crimson;
  background-color: white;
}

.popup-inner1 .close-btn:hover {
  background-color: RGB(220, 53, 70);
  color: white;
}

@media screen and (max-width: 430px) {
  .popup1 {
    width: 94%;
    height: 100vh;
  }
  .pop11 {
    width: 250px;
    margin-left: -13%;
  }
  .popup-inner1 {
    margin-left: 0%;
  }
  .popup-inner1 .close-btn {
    top: 16px;
    right: 2%;
  }
  .popup-inner1 .okayBtn {
    bottom: 16px;
    right: 12%;
    width: 23%;
    height: 12%;
  }
}
