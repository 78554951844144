.join11 {
    position: relative;
    padding: 4%;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.title11 {
    position: absolute;
    padding: 2%;
    font-weight: bold;
    font-size: 2.6vw;
    margin-left: -2vw;
}

.title11 {
    align-items: center;
    padding: 2%;
}

.class{
    margin-top: 4.9vw;
}
@media screen and (max-width: 430px) {
    .join11 {
        padding: 0%;
        margin: 0%;
        margin-top: 0px;
        z-index: 0;
    }
    .join11 .row {
        display: block;
    }
    .join11 .title11 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 5.2vw;
        margin-left: 6.5vw;
        margin-bottom: -60vw;
    }
    .join11 h2 {
        font-size: 34px;
    }
    .join11 p {
        font-size: 16px;
        padding: 1rem;
    }
    .join11 .row .work {
        padding: 2rem;
    }
    .join11 .row .told {
        padding: 2rem;
    }
    .class{
        margin-top: 7.9vw;
    }
}