

 
/* body,html{
  overflow-x: hidden;
} */
.account11{
  display: grid;
  grid-template-columns: 20% auto;
  height: 100%;
  margin-top: 100px;
}
.account11 .left{
  background-color: blue;
  text-align: center;
  align-items: center;
  padding: 10% 0%;

}
.account11 .left h3{
  margin-top: 10%;
  font-size: 5vmin;
  color: white;
  
}

.imgPrint{
   display: flex;
    justify-content: center;
    align-items: center;
  margin: 1% auto;
}
.account11 .left a{
  display: inline-block;
background: rgb(255, 255, 255);
font-size: 3vmin;
color: black;
cursor: pointer;
width: 34vmin;
box-shadow: 0 1px 3px 1px #888888;
text-align: center;
padding: 3.5% 0;
margin-top: 8%;
margin-left: 10px;
border-radius: 25px;
border: none;
transition: all 0.3s ease;
}

/* .account .right{
  background-color: burlywood;
  margin-left: 10px;
}
.account .right h2{
  font-size: 4vmin;
  margin-top: 10%;
  margin-bottom: 4%;
}
.account .right table{
  width: 100%;
}
.account .right td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.account .right tr:nth-child(even) {
  background-color: #dddddd;
}  */
@media(max-width: 584px){
  .account11{
    grid-template-columns: none;
    width: 100%;
    margin-top: 50px;
  }
  
   
}