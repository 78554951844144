@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.popup{position:fixed;top:0;left:0;width:100%;height:100vh;background-color:0,0,0,.2;display:flex;justify-content:center;align-items:center;z-index:100}.popup-inner{position:relative;padding:32px;width:100%;max-width:440px;background-color:#5fcf5f;border-radius:30px}.popup-inner:hover{background-color:#47fa47;border-radius:10px}.popup-inner .close-btn{position:absolute;top:16px;right:16px;border-radius:10px;width:8%}.popup-inner .close-btn:hover{background-color:#f54f4f}.popup-inner h3{font-weight:700}.popup-inner p{font-weight:600}.popWrap{display:flex;margin-top:4%}.pop1{margin-right:3%}@media screen and (max-width: 430px){.popup{width:94%;height:100vh;display:block;margin:3%;margin-top:20%;transform-style:preserve-3d}}
.major{display:fixed;background-color:#fff;justify-content:center;align-items:center;width:80%;height:90vh;box-sizing:border-box;border-radius:15px;margin-bottom:4%;margin-left:6%}.dj{justify-content:center;align-items:center}.major h2{display:flex;align-items:center;justify-content:center;padding-top:3%;color:#04880f}.major label{margin-left:16%;color:#d1cccc;margin-top:1rem}.major input{outline:0;border-width:0 0 3px;border-color:#d1cccc;margin-left:16%;font-size:18px;padding-bottom:1%;width:80%}.major .ex{display:block;padding:1%;margin-left:-8%}.major .btn{justify-items:center;justify-content:center;width:80%;margin-left:16%;margin-top:6%}@media screen and (max-width: 430px){.major{height:69vh}.major h2{font-size:25px}.major .btn{justify-items:center;justify-content:center;width:80%;margin-left:12%;margin-top:6%}}
.major1{display:fixed;background-color:#fff;justify-content:center;align-items:center;width:80%;height:90vh;box-sizing:border-box;border-radius:15px;margin-bottom:4%;margin-left:6%}.major1 h2{align-items:center;justify-content:center;display:flex;padding-top:3%;color:#04880f;font-size:42px}.major1 .ex{display:block;padding:1%;margin-left:-8%}.major1 .label1{margin-left:16%;color:#d1cccc;margin-top:1rem;font-size:18px}.frt{padding:2%;margin-left:17%}input[type=radio]{margin-right:6%}.major1 .label2{margin-left:16%;color:#d1cccc;margin-top:1rem;font-size:21px}.ex27{margin-left:0%;color:#d1cccc;margin-top:1rem;font-size:22px}.ex9{outline:0;border-width:0 0 3px;border-color:#d1cccc;margin-left:1%;font-size:18px;padding-bottom:0%;width:80%}.garuda{font-size:22px;justify-items:center;justify-content:center;width:80%;margin-left:12%;margin-top:6%}@media screen and (max-width: 430px){.major1{height:73vh}.ex27{margin-left:-10px;color:#d1cccc;margin-top:1rem;font-size:18px}.ex9{outline:0;border-width:0 0 3px;border-color:#d1cccc;margin-left:-10px;font-size:18px;padding-bottom:0%;width:100%}.garuda{justify-items:center;justify-content:center;width:80%;margin-left:12%;margin-top:6%;margin-left:16%}}
.op{justify-items:center;justify-content:center}.op p{display:block;margin-left:auto;margin-right:auto;width:50%}.op h1{margin-left:auto;margin-right:auto;width:50%}.center{display:block;margin-left:auto;margin-right:auto;width:50%}@media screen and (max-width: 430px){.op p{width:100%;margin-left:20%}.op{padding-top:13%}.op h1{font-size:22px;font-weight:bold}}
.header {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    padding         : 0px 40px;
    height          : 80px;
    position        : fixed;
    height          : 90px;
    width           : 100%;
    top             : 0;
    left            : 0;
    z-index         : 1;
    transition      : .3s ease-in;
    overflow        : hidden;
background-color: white;

}

.header .navbar {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    max-width      : 1240px;
    margin         : auto;
    margin-bottom: 12%;
    height         : 100%;
    padding        : 0 1rem;
}

.header .nav-menu a {
    color: #000;
    
}

.nav-menu{
    margin-right: 50vw;
    margin-left: 20%;
    z-index:500;
}


.header .nav-menu {
    display: flex;
}

.header .nav-item {
    color                : black;
    display              : grid;
    grid-template-columns: repeat(6, auto);
    grid-gap             : 0px;
    padding              : 0.5rem;
    font-weight          : 500;
    font-size: 15px;
    margin-top:-3% ;
    
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom : 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}

.dragon {
    display      : flex;
    float        : left;
    margin-left  : -5%;
    height       : 4vh;
    margin-top   : 4%;
    padding-top  : 0px;
}

.cho {
    cursor: pointer;
     
    width: 8rem;
    height: 38px;
    margin-top:-11px;
    padding: 10px;
    align-items: center;
}
.cho36 {
    cursor: pointer;
     
    width: 10rem;
    height: 38px;
    margin-top:-11px;
    padding: 10px;
    align-items: center;
    background-color: rgb(108, 192, 192) !important;
}
.cho34 {
    cursor: pointer;
     
    width: 5rem;
    height: 38px;
    margin-top:-11px;
    padding: 10px;
    align-items: center;
}
.cho9 {
    margin-left    : -85%;
    width          : 8rem;
}

.header .dragon .cho9 span {

    padding-left: 12px;
}

.header .dragon .cho span {
    padding-left: 12px;
}
 
.cpUpperLeft img {
    border-radius: 50%;
    height: 43px;
    width: 43px;
    margin-top: -14px;
    margin-left: -1px;
    border: 2px solid #7438FF;
    position: relative; 
    cursor: pointer;
}
.profTitle{
    margin-top: -45px;
    margin-left: 50px;
}
.cpUpperLeft img:hover .menu-content{
    display: flex;
    flex-direction: column;
}
.header .navbar .dragon .cho9 {
    margin-top: -4vw;
    margin-left: 5vw;
    width: 80px;
    padding: 10px;
    cursor: pointer;
}

 
.menu-content {
    position: absolute;
    box-shadow: 0px 2px 6px rgba(0,0,0,20%);
    padding: 10px 2px;
    background-color:white;
    margin-top:-70px;
    border-radius: 5px;
    z-index: 100;
    transition-delay: 0.5s;
     
 }
.menuList{
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0px 10px;
 }
 .links{
    text-decoration: none;
    color: #363636;
    font-family: Roboto;
}


@media screen and (max-width:430px) {
    .header {
        max-width       : 100%;
        z-index         : 1;
        transition      : .3s ease-in;
        overflow        : hidden;
        background-color: rgba(0, 0, 0, .9);
    }
    .cpUpperLeft{
        display: flex;
        flex-direction: row;
        max-width: 10px; 
        
    }  
     .cpUpperLeft img {
        height: 51px;
        margin-left: 3px;
        width: 20px;
    }
    .navprof{
        width: 100px;
    }
    /* .profTitle{
        color: #fff;
        margin-top: -9px;
        margin-left: 9px;
        font-size: 17px;
    } */
    .logo {
        margin-top : 2.2vw;
        width      : 50px;
        height     : 50px;
        margin-left: -10vw;
    }

    .header .nav-menu a {
        color: #fff;
        
    }

    .header .navbar {
        color: white;
        max-width: 100%;
    }

    .header .navbar .nav-menu {
        color: white;
    }

    .hamburger {
        display    : block;
        margin-left: 75vw;
        margin-top : -19vw;
    }
    .nav-menu{
    margin-right: 0%;
    margin-left: 0%;
}


    .nav-menu {
        position        : fixed;
        left            : -100%;
        top             : 90px;
        flex-direction  : column;
        background-color: rgba(0, 0, 0, .9);
        width           : 100%;
        height          : 90vh;
        z-index         : 999;
        text-align      : center;
        transition      : .3s;
    }

    .nav-menu.active {
        left: 0;
        
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 250px;
    }

   

    .signin-up {
        display: none;
    }

    .mobile-menu {
        display: block;
    }


    .dragon {
        display: none;
    }

    .dragon .cho {
        display: none;
    }

    .cho9 {
        display: none;
    }

    .header .navbar .dragon .cho9 span {
        display: none;
    }

    .header .navbar .dragon .cho span {
        display: none;
    }
}
.typography{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);text-align:center;width:80%;color:#fff}.video{margin-top:10.9vw}.welcome{margin-bottom:50px}.chicago{margin-bottom:50px}.dry{font-size:1.5vw}.front{margin-top:10px;width:50% !important;height:inherit !important;border-radius:.25em !important}.ripple{font-size:12px}.ok .btn-success{color:#fff !important;background-color:#8cb649;border:none}.ok .btn-success:hover{background-color:#5cacee !important}@media screen and (max-width: 430px){.welcome{margin-bottom:5px;font-size:24px;display:none}.video12{margin-top:80px}.typography{position:absolute;top:25%;left:50%}.chicago{margin-bottom:25px;font-size:26px;display:none}.dry{display:none}.ok{margin:0}.front{margin-top:10px;width:80% !important;height:inherit !important;border-radius:.25em !important;display:flex;align-items:center;justify-content:center;vertical-align:middle}.ripple{font-size:2px}}
#a{list-style-type:none;text-decoration:none}.row1{list-style-type:none;text-decoration:none}.footImg2{width:20%;margin:5px}.footImg1{width:30%;margin:5px}.footImg3{width:25%;margin:5px}.footer-social{list-style-type:none;padding:0;margin:0}.imgSocialFoot{cursor:pointer}.yelpFoot{margin-left:-9%}#sociallogo{width:100%}@media screen and (max-width: 430px){.footImg2{width:15%;margin:5px}.footImg1{width:25%;margin:5px}.footImg3{width:22%;margin:5px}#sociallogo{width:50%;margin:10px}}
.main{padding-left:1rem;padding-right:1rem;padding-top:8%;padding-bottom:0}.workHead1 h2{display:flex;justify-content:center;color:red;font-size:42px;font-weight:bold;align-items:center}.head27{background-color:#f7f7f7;height:80vh;width:100%}.dummy{background-color:#fff;padding:1rem;margin:1.2rem;justify-content:center;border:2px solid #8db9eb;height:60vh}.content{padding-top:2%}.row1{height:-webkit-max-content;height:max-content}@media screen and (max-width: 430px){.head27{width:100%;height:110vh}.head27 .main{background-color:#f7f7f7;padding:1rem;padding-top:8%;padding-bottom:0;height:140vh;z-index:12}.head27 .main .row1 .dummy{background-color:#fff;padding:1rem;margin:1.2rem;justify-content:center;border:2px solid #8db9eb;height:40vh;width:90%}.workHead1 h2{margin:1% 8%}.head27 .main .row1{display:block}.img{width:50%}.head27 .main .row1 .dummy .col{z-index:12}.content p{font-size:16px}}
.main{padding-left:1rem;padding-right:1rem;padding-top:8%;padding-bottom:0}.workHead1 h2{display:flex;justify-content:center;color:#8cb649;font-size:42px;font-weight:bold;align-items:center}.head27{background-color:#f7f7f7;height:80vh;width:100%}.dummy{background-color:#fff;padding:1rem;margin:1.2rem;justify-content:center;border:2px solid #8cb64a;height:60vh}.content{padding-top:2%}.row1{height:-webkit-max-content;height:max-content}@media screen and (max-width: 430px){#carimg{width:45%;height:18vh}.head27{width:100%;height:160vh}.head27 .main{background-color:#f7f7f7;padding:1rem;padding-top:8%;padding-bottom:0;height:140vh;z-index:12}.head27 .main .row1 .dummy{background-color:#fff;padding:1rem;margin:1.2rem;justify-content:center;border:2px solid #8cb64a;height:40vh;width:90%}.workHead1 h2{margin:1% 8%;font-size:30px;text-align:center}.head27 .main .row1{display:block}.img{width:50%}.head27 .main .row1 .dummy .col{z-index:12}.content p{font-size:16px}}
.main{padding-left:1rem;padding-right:1rem;margin-top:-1vw;height:100%;width:100%}.content{margin-bottom:-10vw}.h41{margin-top:2vw}.h42{margin-top:1.2vw}.h43{margin-top:.9vw}.title{text-align:center;justify-content:center}.p1{font-size:1.2vw;margin-right:10.4vw;color:gray}.head1{background-color:#f7f7f7;height:120vh;width:100%;padding-bottom:2%}.h11{display:flex;align-items:center;justify-content:center}.p1{display:flex;align-items:center;justify-content:center}.main .p1{color:#9c99a1;text-align:center;margin-left:10%}.dummy1{background-color:#f7f7f7;padding:1rem;margin:1.2rem;justify-content:center}.group{padding-top:2%;padding-bottom:5%;margin-bottom:2%}@media screen and (max-width: 430px){.head1 .main .title{display:flex;align-items:center;justify-content:center}.title h1{font-size:34px}.main .p1{color:#9c99a1;justify-content:center}.title p{font-size:16px;font-weight:600}.head1 .main .group{display:block}.title{display:flex;justify-content:center;align-items:center}.h11{display:flex;justify-content:center;align-items:center}.head1 .main .group .dummy1{padding:3rem;margin:1.2rem;justify-content:center;width:inherit}.head1{margin-bottom:2%;height:210vh;width:inherit}}
.title1{text-align:center;justify-content:center}.head69{background-color:#f7f7f7;height:110vh;width:100%;padding-bottom:2%}.dummy3{background-color:#f7f7f7;padding:1rem;margin:1.2rem;margin-bottom:0;justify-content:center}.group{padding-top:2%;padding-bottom:1%}.ch{width:20% !important;justify-content:center;align-items:center;border-radius:24px !important;color:#fff !important}.last69{align-items:center;justify-content:center;margin-bottom:-1;margin-left:40%;padding-top:5%;margin-top:8%}.bar p{font-size:12px;padding:1%}@media screen and (max-width: 430px){.head69 .main7 .group{display:block;width:100%}.head69{padding:2rem;width:100%;height:160vh}.head69 .main7 .group .dummy3{padding-top:3rem}.head69 .main7 .title1 h1{display:inline}.last69{align-items:center;justify-content:center;margin-bottom:-1;margin-left:32%;padding-top:5%;margin-top:8%}}
.join1{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title{align-items:center;padding:2%}.video434{margin-top:5%}.ecoCont{margin-top:-17%}@media screen and (max-width: 430px){.join1{padding:0%;margin:0%;margin-top:0px;z-index:0}.join1 .row{display:block}.join1 h2{font-size:34px}.join1 p{font-size:16px;padding:1rem}.join1 .row .work{padding:2rem}.join1 .row .told{padding:2rem}.ecoCont{margin-top:-5%}.video434{margin-top:-13%}}
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}
.contai {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
:root {
  --primary: hsla(192, 100%, 78%, 0.65);
  --secondary: hsla(192, 100%, 78%, 0.65);
}
.contai .box,
.box1,
.box2 {
  position: relative;
  width: 350px;
  padding: 40px;
  /* background: rgba(255, 255, 255, 0.151); */
  /* background-image: url('./hotel.jpeg'); */
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  box-sizing: border-box;
  overflow: hidden;
  /* background: linear-gradient(135deg, var(--primary), var(--secondary)),
    url("./HOTEL.jpg") no-repeat center / cover; */
}

.contai .box {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/residential.5e7b1ef3.jpg) no-repeat center / cover;
}
.contai .box1 {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/Commercial.7fc34bba.jpg) no-repeat center / cover;
}
.contai .box2 {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/HOTEL.3af99695.jpg) no-repeat center / cover;
}
.contai h3 {
  font-size: 6vmin;
  text-align: center;
  color: #90e0ef;
  font-weight: 900;
  margin: 10px 0;
  padding: 0;
}
.contai p {
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 0;
}

.contai a {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  background: #90e0ef;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
}
.contai a:hover {
  color: #fff;
  border: 2px solid #90e0ef;
  background: none;
  cursor: pointer;
}

.h1211 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1% auto;
}

.indushead {
  color: #8cb649;
  font-size: 42px;
}
@media screen and (max-width: 430px) {
  .jod {
    margin-top: 203px;
    text-align: center;
  }
  .jod p {
    font-size: 16px;
    display: block;
  }
  .jod h2 {
    font-size: 34px;
  }
  .contai .box,
  .box1,
  .box2 {
    margin: 32px;
  }
  .indushead {
    font-size: 30px;
    text-align: center;
  }
}

.container10{padding:4%;background-color:#87ceea}.ch{margin:8px;width:inherit !important;height:inherit !important;color:#fff !important}.ripple{font-size:26px}.riple123{cursor:pointer}@media screen and (max-width: 430px){.container10{margin:0%;padding:2%;background-color:#87ceea;width:100%}.this{margin-left:6%}}
.join3{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.all{margin:3rem;padding:4px}.ch{margin:8px;width:inherit !important;height:inherit !important;color:#fff !important}.video56{margin-top:-2%}@media screen and (max-width: 430px){.ripple{font-size:12px}.all{margin:1rem;padding:4px}.join3{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding:4rem}.join3 h2{font-size:34px}.join3 .ko .ser p{font-size:16px}.join3 .ko .where{align-items:center;justify-content:center}.video56{margin-left:-80px}}
.danny{display:flex;flex:2 1}.danny img{display:fixed}.danny .tro{margin-top:-24%}.danny .tro .lp{display:block;margin-top:20%}.workHead h2{display:flex;justify-content:center;color:red;font-size:42px;font-weight:bold;align-items:center}.danny .tro .lp p{color:#9c99a1;padding-top:4%;font-weight:600}.danny .no{display:block;margin-right:4%}.danny .no .ll p{justify-content:center;color:#9c99a1;align-items:center}.danny .no .zz{margin-top:6%}.juice{margin-left:35%}.imgwork{margin-left:40%}.imgwork1{margin-left:35%}@media screen and (max-width: 430px){.danny{display:flex;flex-direction:column;margin-top:20%;padding-bottom:0;height:100vh}.workHead h2{margin-top:60%}.danny .hd{z-index:-639654;width:90%;height:120vh}.danny .tro{margin-top:2%}.danny .no .zz{display:block;border-color:#fff}.danny .no .ll p{display:flex;justify-content:center;padding-left:4%;color:#140d0d}.juice{margin-left:42%}.imgwork{margin-left:45%}.imgwork1{margin-left:42%}.danny .tro .lp h2{padding-left:4%}.danny .tro .lp p{padding-left:4%;color:#000}.danny .no .ll{margin-top:10%}}
/* services section styling */
/* slider start*/

.container2 {
  max-width: 100vw;
}
.container2 .carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.container2 .carousel::-webkit-scrollbar {
  display: none;
}

.container2 .carousel .item {
  margin: 8vmin 2vmin;
  padding: 4vmin;
  width: 60vmin;
  border-radius: 16px;
  flex: none;
}
.container2 .carousel .item {
  box-shadow: 10px 20px 9px rgba(0, 0, 0, 0.5);
  background: #333;
  border-top: 3px solid white;
  border-left: 3px solid white;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.container2 .carousel .item:hover {
  background: crimson;
}
.container2 .carousel .item .box {
  transition: all 0.3s ease;
}
.container2 .carousel .item:hover .box {
  transform: scale(1.05);
}
.container2 .buttons {
  width: 100%;
  text-align: center;
}
.container2 .buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.container2 .buttons button:first-child img {
  transform: rotate(180deg);
}

/* .set img{
      border-radius: 50%;
      width: 30%;
      height: auto;
  } */
.item img {
  border-radius: 50%;
  /* border: 3px solid white; */
  width: 25%;
  height: auto;
}
.container2 .carousel .box h4 {
  font-size: 3vmin;
  font-weight: 600;
}
.container .carousel .item .name {
  font-size: 2.8vmin;
  font-weight: bold;
  margin-left: 9%;
  text-decoration: none;
  margin-top: 900%;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.stars {
  color: yellow;
  margin-left: 30%;
}

.description {
  color: #fff;
  font-size: 2vmin;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.services,
.teams {
  color: #fff;
  padding: 5% 0;
  background-color: rgba(221, 221, 221, 0.959);
}
.services h2 {
  font-size: 7vmin;
  color: rgb(0, 0, 0);
  text-align: center;
  padding-bottom: 30px;
  margin: 2px auto;
}
.services h3 {
  font-size: 3vmin;
  text-align: center;
  color: black;
  padding-bottom: 25px;
}
.services h2 span {
  text-decoration: underline;
  text-underline-position: under;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
}

.services .serv-content .card {
  /* height: calc(33% - 2%); */
  width: 60vmin;
  height: 70vmin;
  background: #222;
  text-align: center;
  border-radius: 6px;
  padding: 5vmin 8vmin;
  cursor: pointer;
  transition: all 0.3s ease;
}
.services .serv-content .card:hover {
  background: crimson;
}
.services .serv-content .card .box {
  transition: all 0.3s ease;
}
.services .serv-content .card:hover .box {
  transform: scale(1.05);
}
.services .serv-content .card i {
  font-size: 50px;
  color: crimson;
  transition: color 0.3s ease;
}
.services .serv-content .card:hover i {
  color: #fff;
}
.services .serv-content .card .text {
  font-size: 4vmin;
  font-weight: 500;
  /* margin: 10px 0 7px 0; */
}
.services .serv-content .card p {
  font-size: 2.4vmin;
}
.services .serv-content .card h5 {
  font-size: 2.4vmin;
  font-weight: 450;
}
.services .serv-content .card h4 {
  font-size: 2.4vmin;
}
.services .serv-content .card img {
  height: 18vmin;
  width: 18vmin;
  /* object-fit: cover; */
  border-radius: 50%;
  /* border: 5px solid crimson; */
  transition: all 0.3s ease;
}
.last691 {
  margin-left: 42%;
}

.reviewContainer {
  height: 400px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewWrapper {
  width: 100%;
  height: 85%;

  display: flex;
}

.reviewRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviewBox {
  border-radius: 5%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-around;
  box-shadow: 0px 0px 27px -10px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0px 0px 27px -10px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 27px -10px rgba(0, 0, 0, 0.45);
}
.reviewinput {
  display: flex;
  width: 100%;
  position: relative;
}
.reviewinput label {
  position: absolute;
  top: 8px;
  left: 10px;
  color: rgb(117 98 98);
  text-transform: capitalize;
  font-size: 18px;
  transition: top 0.3s;
  padding: 0 5px;
  pointer-events: none;
}
.reviewinput input:focus ~ label,
.reviewinput input:not(:focus):valid ~ label {
  top: -12px;
  background-color: white;
  font-size: 15px;
  color: #1775ee;
  border-radius: 10px;
}

.reviewupInput {
  height: 40px;
  background: transparent;
  padding-left: 20px;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid grey;
  outline: none;
  width: 100%;
}
.reviewupInput:focus {
  border: 1px solid blue;
}

.reviewButton {
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.reviewupButton {
  background-color: #283290;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.reviewButton:disabled {
  cursor: not-allowed;
}

.mobBox {
  width: 600px;
}

@media screen and (max-width: 430px) {
  .mobBox {
    width: 380px;
  }
  .reviewWrapper {
    width: 90%;
  }
  .last691 {
    margin-left: 34%;
  }
}

 
.searchheight1{
    margin: 10vmin 0;
}
.searchtxt1{
    text-align: center;
    margin: 10px 0;
    font-weight: 600;
    font-size: 4vmin;
    color: #444;
}
.conttainerr1{
    margin-bottom: 10vmin;
    width: 1200px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
     
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    grid-gap: 10px; */
    padding: 15px;
    box-sizing: border-box;
}
.conttainerr1 .boxx1{
    position: relative;
    background: #fff;
    /* padding: 20px 40px 40px; */
    text-align: center;
    overflow: hidden;
    border: 2px;
    margin-right: 10px;
}
.conttainerr1 .boxx1 p{
    font-size: 2.4vmin;
    text-align: left;
    line-height: 30px;
    color: #555;
    font-weight: 400;
}

@media screen and (max-width: 430px) {
    .conttainerr1{
         display: flex;
         flex-direction: column;
         width: 400px;
         margin: 1px auto;
    }
    .conttainerr1 .boxx1{
        margin:1px auto;
    }
    .conttainerr1 .boxx1 p{
        font-size: 3.5vmin;
       
        font-weight: 400;
    }
  }
  
.refer-form {
  width: 80% !important;
}

.ss-parent {
  /* margin-bottom: -240px !important; */
  z-index: 1 !important;
  width: 100%;
  margin: 18px auto;
  background-color: rgb(255, 255, 255);
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 2.4em;
  font-family: "Work Sans";
}

.ss-heading {
  font-size: 34px;
  font-weight: bold;
  padding: 14px 0px;
}
.ss-sub-heading {
  font-size: 22px;
  font-weight: bold;
  padding: 4px 0px;
}

.ss-sub-text {
  padding: 14px 0px;
  color: #7e89a3;
}

.refer-ss-section {
  margin-bottom: -216px !important;
}

.refer-form .refer-form-addon-flag {
  display: flex !important;
  align-items: center !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.refer-form .refer-form-flag {
  width: 26px;
  height: 20px;
  margin-right: 8px;
}

.refer-form .refer-form-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: none;
  border-left: none;
}
.refer-form input {
  color: #000 !important;
}

.refer-form .refer-form-addon-button {
  display: flex;
  align-items: center;
  border-radius: 0.25rem 0px 0px 0.25rem;
  border: none;
}
.refer-form .refer-form-button {
  background-color: rgb(16, 16, 16);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  padding: 0px 1.6em;
  height: 100%;
}

.appImg{
  border-radius: 15px;
}
/* mobile screen */
@media only screen and (max-width: 768px) {
  .ss-parent {
    padding: 1em;
  }
  .ss-parent .ss-heading {
    font-size: 26px !important;
  }
  .ss-parent .ss-sub-text {
    font-size: 16px !important;
  }

  .store-icons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }
  .store-icons img {
    margin: -3% 5px !important;
  }
  .ss-section {
    margin-top: 20px !important;
  }
  .ss-section img {
    width: 180px !important;
    margin: auto 5px !important;
  }
  .refer-ss-section {
    margin-top: 1em;
    margin-bottom: -216px !important;
  }
  .refer-ss-section #refer-ss {
    width: 320px !important;
  }

  .refer-form {
    width: 100% !important;
    font-size: 0.8em !important;
  }
  .refer-form .refer-form-button {
    padding: 0px 1em;
    height: 100%;
  }

 
}

.popup1{position:fixed;top:0;left:0;width:100%;height:100vh;background-color:0,0,0,.2;display:flex;justify-content:center;align-items:center;z-index:100}.popup-inner1{position:relative;padding:0px;width:100%;max-width:300px;margin-left:-8%;border-radius:30px}.popup-inner1:hover{background-color:#fdfdfd;border-radius:10px}.popImg222{border-radius:8px}.popup-inner1 .close-btn{position:absolute;top:16px;right:-27%;border-radius:5px;width:12%;border:none}.popup-inner1 .okayBtn{position:absolute;bottom:18px;right:0;border-radius:5px;width:23%;height:10%}.popup-inner1 .okayBtn:hover{border:1px solid crimson;color:crimson;background-color:#fff}.popup-inner1 .close-btn:hover{background-color:RGB(220, 53, 70);color:#fff}@media screen and (max-width: 430px){.popup1{width:94%;height:100vh}.pop11{width:250px;margin-left:-13%}.popup-inner1{margin-left:0%}.popup-inner1 .close-btn{top:16px;right:2%}.popup-inner1 .okayBtn{bottom:16px;right:12%;width:23%;height:12%}}
.danny{display:flex;flex:2 1}.danny img{display:fixed}.danny .tro{margin-top:-24%}.workP{width:68%;margin:0px auto}.danny .tro .lp{display:block;margin-top:20%}.workHead h2{display:flex;justify-content:center;color:#8cb649;font-size:42px;font-weight:bold;align-items:center}.danny .tro .lp p{color:#9c99a1;padding-top:4%;font-weight:600}.danny .no{display:block;margin-right:4%}.danny .no .ll p{justify-content:center;color:#9c99a1;align-items:center}.danny .no .zz{margin-top:0%}.lp .ptext{display:flex;justify-content:center;align-items:center}.hd{display:flex;justify-content:center;align-items:center;width:50%;margin:0% auto}.juice{margin-left:35%}.imgwork{margin-left:40%}.imgwork1{margin-left:35%}@media screen and (max-width: 430px){.danny{display:flex;flex-direction:column;margin-top:20%;padding-bottom:0;height:100vh}.workHead h2{margin-top:70%;font-size:30px;text-align:center}.lp .ptext{color:#140d0d;margin-top:-51%;margin-left:10%}.danny .hd{width:45%;height:18vh}.danny .tro{margin-top:2%}.danny .no .zz{display:block;border-color:#fff}.workP{width:95%}.danny .no .ll p{display:flex;justify-content:center;padding-left:4%;color:#140d0d}.juice{margin-left:42%}.imgwork{margin-left:45%}.imgwork1{margin-left:42%}.danny .tro .lp h2{padding-left:4%}.danny .tro .lp p{padding-left:4%;color:#000}.danny .no .ll{margin-top:10%}}
.danny788{display:flex;flex:2 1}.zz11{margin:4% auto}.danny778 img{display:fixed}.danny788 .tro788{margin-top:-15%}.workP788{width:68%;margin:0px auto}.danny788 .tro788 .lp788{display:block;margin-top:20%}.workHead788 h2{display:flex;justify-content:center;color:#8cb649;font-size:42px;font-weight:bold;align-items:center}.danny788 .tro788 .lp788 p{color:#9c99a1;padding-top:4%;font-weight:600}.danny788 .no788{display:block;margin-right:4%}.danny788 .no788 .ll788 p{justify-content:center;color:#9c99a1;align-items:center}.danny788 .no788 .zz778{margin-top:0%}.lp788 .ptext788{display:flex;justify-content:center;align-items:center}.hd788{display:flex;justify-content:center;align-items:center;width:40%;margin:0% auto}.juice{margin-left:35%}.imgwork778{margin-left:40%}.text-center788{display:flex;justify-content:center;align-items:center;margin-top:12px}.imgwork1788{margin-left:35%}@media screen and (max-width: 430px){.danny788{display:flex;flex-direction:column;margin-top:20%;padding-bottom:0;height:460vh}.zz11{display:flex;flex-direction:column}.workHead788 h2{margin-top:70%;font-size:30px;text-align:center}.lp788 .ptext788{color:#140d0d;margin-top:-51%}.danny788 .hd788{width:45%;height:18vh}.danny788 .tro788{margin-top:-48%}.danny788 .no788 .zz788{display:block;border-color:#fff}.workP788{width:95%}.danny788 .no788 .ll788 p{display:flex;justify-content:center;padding-left:4%;color:#140d0d}.juice{margin-left:42%}.imgwork788{margin-left:45%}.imgwork1788{margin-left:42%}.danny788 .tro788 .lp788 h2{padding-left:4%}.danny788 .tro788 .lp788 p{padding-left:4%;color:#000}.danny788 .no788 .ll788{margin-top:10%;margin-left:3%}}
.join1{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title{padding:2%;font-weight:bold;font-size:2.2vw;margin-left:-50vw}.video1{margin-top:-5rem}.title{align-items:center;padding:2%}@media screen and (max-width: 430px){.join1{padding:0%;margin:0%;margin-top:0px;z-index:0}.join1 .row{display:block}.join1 .title{font-weight:bold;font-size:5.2vw;margin-left:5vw}.join1 h2{font-size:34px}.join1 p{font-size:16px;padding:1rem}.join1 .row .work{padding:2rem}.join1 .row .told{padding:2rem}}
.join{padding:4%;background-color:#f7f7f7;width:100%;height:100%}.title p{text-align:center;justify-content:center}.bg{padding:1em}.centered1{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.align-items-center{font-size:1.2vw;margin-right:1.4vw;color:gray}@media screen and (max-width: 430px){.join{margin-top:64%}}@media screen and (max-width: 430px){.jod p{font-size:16px;display:block}.jod h2{font-size:34px}.join{margin-bottom:0px}.align-items-center{margin-right:8.4vw}}
.join1{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title{align-items:center;padding:2%}@media screen and (max-width: 430px){.join1{padding:0%;margin:0%;margin-top:0px;z-index:0}.join1 .row{display:block}.join1 h2{font-size:34px}.join1 p{font-size:16px;padding:1rem}.join1 .row .work{padding:2rem}.join1 .row .told{padding:2rem}}
.join{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title{padding:2%;font-weight:bold;font-size:2.2vw;margin-left:-50vw}.para{font-size:1.3vw;margin-bottom:4.4vw}.video-box-thumb{margin-right:4vw}.Title-inner{margin-bottom:5vw}.skills{display:grid;grid-template-columns:repeat(1, 1fr);grid-row-gap:2rem;grid-column-gap:3rem}.join1{padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title{align-items:center;padding:2%}@media screen and (max-width: 430px){.join1{padding:0%;margin:0%;margin-top:0px;z-index:0}.join1 .row{display:block}.join1 h2{font-size:34px}.join1 p{font-size:16px;padding:1rem}.join1 .row .work{padding:2rem}.join1 .row .told{padding:2rem}}
.about{background-color:#6d9fb2;padding:2vw;color:#fff;margin-top:5vw}.p{margin-left:24vw}.p1{margin-left:-10vw;padding-top:1vw;color:#fff}.p2{padding-top:1vw;color:#fff}.h1{display:none}@media screen and (max-width: 430px){.about{margin-top:23vw}.h1{display:flex;margin-left:4vw}.p{font-size:3.2vw;margin-left:10.1vw}.p1{display:flex;font-size:3.2vw;margin-left:10vw;margin-bottom:-4vw;margin-top:1vw}.p2{margin-top:2vw}}
.container1{padding:4%;background-color:#87ceea}
.special{background-color:#6d9fb2;padding:2vw;color:#fff;margin-top:5vw}.p5{display:flex;align-items:center;justify-content:center}.h1{display:none}@media screen and (max-width: 430px){.special{margin-top:30vw}.h1{display:flex;align-items:center;justify-content:center}.p5{display:flex;align-items:center;justify-content:center;font-size:3.3vw}}
.head{display:flex;align-items:center;justify-content:center;padding:10vw 10vw 10vw 10vw;font-size:1.2vw;color:#8a7a77;font-weight:500}.b1{margin-left:3vw;background-color:#87ceea;color:#fff;border:none;padding:1vw 1vw 1vw 1vw}@media screen and (max-width: 430px){.head{font-size:3.5vw;position:relative}.b1{position:absolute;margin-top:25vw;margin-right:60vw;padding:2vw 2vw 2vw 2vw}}
.p9{border:1px solid #9f9b97;padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:500;font-size:1.1vw;margin:5vw 10vw -2vw 10vw;transition:all .5s ease}@media screen and (max-width: 430px){.p9{display:flex;align-items:center;justify-content:center;border:1px solid #9f9b97;padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:500;font-size:3.5vw;margin:5vw 5vw 5vw 5vw}}
.join11{position:relative;padding:4%;background-color:#fff;width:100%;height:100%;z-index:10}.title11{position:absolute;padding:2%;font-weight:bold;font-size:2.6vw;margin-left:-2vw}.title11{align-items:center;padding:2%}.class{margin-top:4.9vw}@media screen and (max-width: 430px){.join11{padding:0%;margin:0%;margin-top:0px;z-index:0}.join11 .row{display:block}.join11 .title11{display:flex;align-items:center;justify-content:center;font-weight:bold;font-size:5.2vw;margin-left:6.5vw;margin-bottom:-60vw}.join11 h2{font-size:34px}.join11 p{font-size:16px;padding:1rem}.join11 .row .work{padding:2rem}.join11 .row .told{padding:2rem}.class{margin-top:7.9vw}}
.pricing{background-color:#6d9fb2;padding:2vw;color:#fff;margin-top:5vw}.p{margin-left:24vw}.p1111{margin-left:18vw;padding-top:1vw;color:#fff}.p2{padding-top:1vw;color:#fff}.h1{display:none}@media screen and (max-width: 430px){.pricing{margin-top:23vw}.h1{display:flex;margin-left:4vw}.p{font-size:3.2vw;margin-left:10.1vw}.p1111{display:flex;font-size:3.2vw;margin-left:18vw;margin-bottom:-3vw;margin-top:.2vw}.p2{margin-top:2vw}}
.join112{position:relative;padding:4%;background-color:#f7f7f7;width:100%;height:100%;z-index:10}.title112{position:absolute;padding:2%;font-weight:bold;font-size:3.2vw;margin-left:44.5vw;margin-top:-3vw}@media screen and (max-width: 430px){.join112{padding:0%;margin:0%;margin-top:0px;z-index:0}.join112 .row{display:block}.join112 .title112{display:flex;align-items:center;justify-content:center;font-weight:bold;font-size:5.2vw;margin-top:69vw;margin-left:7vw}.join112 h2{font-size:34px}.join112 p{font-size:16px;padding:1rem}.join112 .row .work{padding:2rem}.join112 .row .told11{padding:2rem}}
.payment{border:1px solid #9f9b97;padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:400;margin:2vw 10vw -2vw 10vw;transition:all .5s ease}.pay1{color:#000;font-weight:600;font-size:2.8vw}.p98{font-weight:500}@media screen and (max-width: 430px){.payment{border:1px solid #9f9b97;padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:500;font-size:3.5vw;margin:5vw 5vw 5vw 5vw}.pay1{display:flex;align-items:center;justify-content:center;font-weight:700;font-size:7.2vw}.p99{display:flex;align-items:center;justify-content:center;font-weight:400;font-size:3.8vw;margin-top:3.2vw}.p98{display:flex;align-items:center;justify-content:center;font-weight:500;font-size:3.8vw}}
.tabl1{border:1px solid #efefef;padding:1vw 0vw 1vw 0vw}.tab11{font-weight:600;font-size:1.8vw}.tab2{margin-top:2vw}.tab3{position:relative;display:flex;align-items:flex-end;justify-content:flex-end;margin-right:3vw;margin-top:-2vw;font-weight:600;font-size:1.8vw}@media screen and (max-width: 430px){.tabl1{border:1px solid #efefef;padding:1vw 0vw 1vw 0vw}.tab11{font-weight:600;font-size:3.8vw}.tab2{width:60vw;margin-top:2vw;font-size:3.2vw}.tab3{position:relative;display:flex;align-items:flex-end;justify-content:flex-end;margin-right:3vw;margin-top:-6vw;font-weight:600;font-size:3.8vw}}
.initiate{background-color:#f7f7f7;padding:3vw 3vw 3vw 3vw}.table1{border:1px solid #9f9b97;padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:400;margin:2vw 10vw -2vw 10vw;transition:all .5s ease;background-color:#fff}@media screen and (max-width: 430px){.table1{padding:3vw 3vw 3vw 3vw;color:#8a7a77;font-weight:400;margin:0vw 0vw 0vw 0vw;transition:all .5s ease;background-color:#fff}}
.scrollToTop{background-color:#121258;border-radius:100%;position:fixed;height:4rem;width:4rem;bottom:80px;right:40px;z-index:2000;cursor:pointer;display:flex;justify-content:center;align-items:center}.scrollToTop svg{color:#fff;font-size:2rem}.scrollToTop:hover{background-color:#fff}.scrollToTop:hover svg{color:#121258}
.catBody {
  display: flex;
  justify-content: space-between;
}

.catTitle {
  display: flex;
  flex-direction: row;
}

.catTitle h4 {
  margin-left: 20px;
}

.btn-success {
  background-color: rgb(140, 182, 74);
}

.venBody
{
   display: flex;  
   justify-content: space-between;
}

.venTitle{
    display: flex;
    flex-direction: row;
}

.venTitle h4{
    margin-left: 20px;
}
.cardcont {
  display: flex;
}
.cardit {
  display: flex;
}
.itemBody {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.makeStyles-grid-4 {
  padding: 0 15px !important;
  margin: 10px auto;
}
/* .rowCard{
    margin: 10px;
} */
.itemTitle {
  display: flex;
  flex-direction: column;
}

.removeCart1 {
  cursor: pointer;
  color: red;
  margin: 2%;
}
.titCon h4 {
  margin-left: 20px;
}
.titCon h6 {
  margin-left: 20px;
}
.titCon p {
  margin-left: 20px;
}
.titCon {
  display: flex;
  flex-direction: column;
}
.itemInc {
  margin-right: -1000px;
}
.itemBut {
  background-color: red !important;
}

@media screen and (max-width: 1115px) {
  #itemRow {
    width: 75em;
  }

  #detail {
    width: 900px;
  }
  .itemInc {
    margin-right: -100px;
  }
}

@media screen and (max-width: 700px) {
  #itemRow {
    width: 40em;
  }
  #cardimg {
    min-width: 100px;
  }
  #detail {
    width: 400px;
    font-size: 10px;
  }
  #btnn {
    margin-right: -100px;
  }
}

@media screen and (max-width: 430px) {
  .itemInc {
    margin-right: 10px;
  }
  .itemBody {
    display: flex;

    flex-direction: column;
  }
  /* .cardcont{
        display: flex;
    }
    .cardit{
        display: flex;
         
    } */

  #cardimg {
    min-width: 150px;
  }

  #btnn {
    margin-right: unset;
  }

  #detail {
    width: unset;
    font-size: unset;
  }

  #itemRow {
    width: unset;
  }
}

.addtocartpage{
    display: flex;
    justify-content: center;
    background-color: #f0f2f5;
    height: calc(100vh - 50px);
}
.addtocartpageWrapper{
    margin: 20px 0px;
    width: 80%;
}
.footer{
    float: right;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
}
.gt{
    margin-right: 50px;
}
 
.storeregInput {
    background: transparent;
    border-radius: 10px;
    padding-left: 3%;
    border: 1px solid grey;
    outline: none;
     height: 30px;
}
.storeregInput:focus {
    border: 1px solid blue;
}

 
.ckButton{
    background-color: #42b72a;
    margin: 20px;
    font-size: 15px;
    font-weight: 500;
    color: white;
    height: 40px;
    padding: 10px 50px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.idTest{
    height: 10px;
    font-size: 17px;
    padding: 10px;
}
.idButton{
    height: 30px;
    width: 30px;
    background-color: red;
    color: white;
    border: none;
    outline: none;
    font-size: 25px;
    cursor: pointer;
}
 .col-3{
     width: 180px !important;
 }




/* cart item design */
.cartitem{
    margin: 10px 2px;
    background-color: white;
}
.cartitemWrapper{
    padding: 10px;
    display: flex;
}

.cartHeader{
    display: flex;
}
.chleft{
    flex: 0.7 1;
    display: flex;
    flex-direction: row;
}
.removeCart{
    cursor: pointer;
}
.secDiv{
    flex: 0.3 1;
    display: flex;
}
.chmid{
    flex: 0.5 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chright{
    flex: 0.5 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartItemSpinner{
    padding: 8px;
}

.cartHeaderImg{
    height: 120px;
    width: 130px;
}
.headerText{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}
.quantityDiv{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}
.headerText h4{
    margin: 4px 0px;
    padding: 0;
}
.ciPrice{
    font-weight: 500;
    font-size: 18px;
}
.removeCart{
    color: red;
}
.row1{
    margin: 10px auto;
}
.btn-primary{
    color : white !important
}

.addzip{
    margin-top: 4%;
}
.modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3.3rem !important;
    outline: 0;
}
/* .modal11{
    border-radius: 10% !important;
     
} */

.contBtn{
    width: 50%;
    margin: auto;
}
@media only screen and (max-width: 650px) {
    .addtocartpageWrapper{
        margin: 10px 0px;
        width: 95%;
        height: calc(100vh - 50px);
    }
    .cartitemWrapper{
        flex-direction: column;
    }
    
    .row1{
        flex-direction: column;
    }
}
/* @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap'); */
/* *{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
/* body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
} */
.bluelinenew{
    background: rgb(95, 177, 255);
    height: 13vmin;
    margin-top: 109px;
    width: 100vw;
    margin-bottom: 30px;
}
.bluelinenew p{
    /* margin-top: auto; */
    padding: 2.3% 0;
    font-size: 2.2vmin;
    font-weight: 500;
    text-align: center;
    color: white;
}
.contactpage{
    display: flex;
    height: 100vh;
     
  }
  .signupContainer {
    height: calc(100vh - 50px);
    width: 60%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:5px
}
.text{
  /* text-align: center; */
  font-size: 35px;
  font-weight: 600;
  color: #000;
}
  .tea{
    font-size: 2.6vmin;
    color: #444;
    margin-top: 1%;
}
.signupWrapper {
    width: 80%;
    height: 85%;
     
    display: flex;
}

.signupRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signupBox {
    border-radius: 5%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-around;
    box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
    -webkit-box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
}
.divinput{
    display: flex;
     width: 100%;
     position: relative;
      
 }
 .divinput label{
        position: absolute;
        top: 8px;
        left: 10px;
        color: rgb(117 98 98);
        text-transform: capitalize;
        font-size: 18px;
        transition: top .3s;
        padding: 0 5px;
        pointer-events: none;
 }
 .divinput input:focus ~ label, .divinput input:not(:focus):valid ~ label{
        top:-12px;
        background-color:white ;
        font-size: 15px;
        color: #1775ee;
        border-radius: 10px;
}
      
.signupInput {
    height: 40px;
    background: transparent;
    padding-left: 20px;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid grey;
    outline: none;
    width: 100%;
}
.signupInput:focus {
    border: 1px solid blue;
}
 

.signupButton, .loginRegisterButton {
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.signupButton{
    background-color: #283290;
    font-size: 20px;
    font-weight: 500;
    color: white;
}
.signupButton:disabled{
    cursor: not-allowed;
}
 
.contactpage .right{
    
  margin-top: 4rem ;
  margin-left: 30px;
  align-items: center;
  justify-content: center;
}
 .right h2{
   
  font-weight: 600;
  margin-bottom: 40px;
}
.right h3{
   
  font-weight: 400;
  margin-bottom: 10px;
}
.atag{
   margin-bottom: 35px;
 }
@media only screen and (max-width: 768px) {
  .contactpage{
    flex-direction: column;
  }
   


.signupContainer {
  margin:5px auto;
  width:80%
}
.signupWrapper {
  width: 100%;
    
}

.tea{
  font-size: 3.6vmin;
  
}
.contactpage .right{
    
    margin-top: 15px ;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
}
 

}
/* *{
    box-sizing: border-box;
}

.colored-box{
    background: rgb(71, 212, 255);
}
.colored-box h1{
    text-align: center;
    font-size: 6vmin;
    font-weight: 600;
}
.colored-box p{
    text-align: center;
    font-size: 2.8vmin;
    color: #fff;
}

.gallery img{
    width: 100%;
    height: auto;
}

.responsive{
    background: rgb(71, 212, 255);
    padding: 0 6px;
    float: left;
    width: 25%;
}
@media only screen and (max-width: 700px){
    .responsive{
        width: 50%;
        margin: 6px 0;
    }
}
@media only screen and (max-width: 500px){
    .responsive{
        width: 100%;
        
    }
} */

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    min-height: 100vh;
    overflow-x: hidden;
}
.main{
    position: absolute;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width:100vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px,300px));
    column-gap: 10px;
    row-gap: 10px;
}
.main img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    object-position: center;
} */
 
.gallery-back h1{
    padding: 3% 0;
    text-align: center;
    font-size: 6vmin;
    font-weight: 600;
    color: #fff;
    
}
.gallery-back p{
    text-align: center;
    font-size: 2.8vmin;
    color: #fff;
    padding-bottom: 2%;
}
.gallery-back{
    background: rgb(71, 212, 255);
    margin: 0.2%;
    margin-top: 80px;
}
.main1{
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 10px; */

}
.main1 img{
    padding: 10px;
}


 
/* body,html{
  overflow-x: hidden;
} */
.account11{
  display: grid;
  grid-template-columns: 20% auto;
  height: 100%;
  margin-top: 100px;
}
.account11 .left{
  background-color: blue;
  text-align: center;
  align-items: center;
  padding: 10% 0%;

}
.account11 .left h3{
  margin-top: 10%;
  font-size: 5vmin;
  color: white;
  
}

.imgPrint{
   display: flex;
    justify-content: center;
    align-items: center;
  margin: 1% auto;
}
.account11 .left a{
  display: inline-block;
background: rgb(255, 255, 255);
font-size: 3vmin;
color: black;
cursor: pointer;
width: 34vmin;
box-shadow: 0 1px 3px 1px #888888;
text-align: center;
padding: 3.5% 0;
margin-top: 8%;
margin-left: 10px;
border-radius: 25px;
border: none;
transition: all 0.3s ease;
}

/* .account .right{
  background-color: burlywood;
  margin-left: 10px;
}
.account .right h2{
  font-size: 4vmin;
  margin-top: 10%;
  margin-bottom: 4%;
}
.account .right table{
  width: 100%;
}
.account .right td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.account .right tr:nth-child(even) {
  background-color: #dddddd;
}  */
@media(max-width: 584px){
  .account11{
    grid-template-columns: none;
    width: 100%;
    margin-top: 50px;
  }
  
   
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}
.account112{
    display: grid;
    grid-template-columns: 20% auto;
    height: 100%;
    margin-top: 80px;
  }
  .account112 .left12{
    background-color: blue;
    text-align: center;
    align-items: center;
    padding: 10% 0%;
  
  }
  .account112 .left12 h3{
    margin-top: 10%;
    font-size: 5vmin;
    color: white;
    
  }
  .account112 .left12 a{
    display: inline-block;
  background: rgb(255, 255, 255);
  font-size: 3vmin;
  color: black;
  cursor: pointer;
  width: 34vmin;
  box-shadow: 0 1px 3px 1px #888888;
  text-align: center;
  padding: 3.5% 0;
  margin-top: 8%;
  margin-left: 10px;
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease;
  }
.profilebody112{
  height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.container112{
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container112 .titlee112{
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
.container112 .titlee112::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.conttent112 form .userdetailss112{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .userdetailss112 .inputboxl112{
  margin-bottom: 15px;
  width: 100%;
}
form .inputboxl112 span.details112{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.userdetailss112 .inputboxl112 input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.userdetailss112 .inputboxl112 input:focus,
.userdetailss112 .inputboxl112 input:valid{
  border-color: #9b59b6;
}
 form .genderdetails112 .gendertitlee112{
  font-size: 20px;
  font-weight: 500;
 }
 form .category{
   display: flex;
   width: 80%;
   margin: 14px 0 ;
   justify-content: space-between;
 }
 form .category label{
   display: flex;
   align-items: center;
   cursor: pointer;
 }
 form .category label .dot{
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #d9d9d9;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
 #dot-1:checked ~ .category label .one,
 #dot-2:checked ~ .category label .two,
 #dot-3:checked ~ .category label .three{
   background: #9b59b6;
   border-color: #d9d9d9;
 }
 form input[type="radio"]{
   display: none;
 }
 form .button112{
   height: 45px;
   margin: 35px 0
 }
 form .button112 input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   background: linear-gradient(135deg, #71b7e6, #9b59b6);
 }
 form .button112 input:hover{
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
  }
 @media(max-width: 584px){
 .container112{
  max-width: 100%;
}
form .userdetailss112 .inputboxl112{
    margin-bottom: 15px;
    width: 100%;
  }
  form .category{
    width: 100%;
  }
  .conttent112 form .userdetailss112{
    max-height: 300px;
    overflow-y: scroll;
  }
  .userdetailss112::-webkit-scrollbar{
    width: 5px;
  }
  }
  @media(max-width: 459px){
  .container112 .conttent112 .category{
    flex-direction: column;
  }
}
@media(max-width: 584px){
    .account112{
      grid-template-columns: none;
     
    }
  }
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}
.account21{
    display: grid;
    grid-template-columns: 20% auto;
    height: 100%;
    margin-top: 80px;
  }
  .account21 .left{
    background-color: blue;
    text-align: center;
    align-items: center;
    padding: 10% 0%;
  
  }
  .account21 .left h3{
    margin-top: 10%;
    font-size: 5vmin;
    color: white;
    
  }
  .account21 .left a{
    display: inline-block;
  background: rgb(255, 255, 255);
  font-size: 3vmin;
  color: black;
  cursor: pointer;
  width: 34vmin;
  box-shadow: 0 1px 3px 1px #888888;
  text-align: center;
  padding: 3.5% 0;
  margin-top: 8%;
  margin-left: 10px;
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease;
  }
.profilebody21{
  height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.container21{
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container21 .titlee21{
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
.container21 .titlee21::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.conttent21 form .userdetails21{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .userdetails21 .inputbox21{
  margin-bottom: 15px;
  width: calc(100% / 2 - 20px);
}
form .inputbox21 span.details21{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.userdetails21 .inputbox21 input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.userdetails21 .inputbox21 input:focus,
.userdetails21 .inputbox21 input:valid{
  border-color: #9b59b6;
}
 form .genderdetails21 .gendertitle21{
  font-size: 20px;
  font-weight: 500;
 }
 form .category21{
   display: flex;
   width: 80%;
   margin: 14px 0 ;
   justify-content: space-between;
 }
 form .category21 label{
   display: flex;
   align-items: center;
   cursor: pointer;
 }
 form .category21 label .dot{
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #d9d9d9;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
 #dot-1:checked ~ .category21 label .one,
 #dot-2:checked ~ .category21 label .two,
 #dot-3:checked ~ .category21 label .three{
   background: #9b59b6;
   border-color: #d9d9d9;
 }
 form input[type="radio"]{
   display: none;
 }
 form .button21{
   height: 45px;
   margin: 35px 0
 }
 form .button21 input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   background: linear-gradient(135deg, #71b7e6, #9b59b6);
 }
 form .button21 input:hover{
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
  }
 @media(max-width: 584px){
 .container21{
  max-width: 100%;
}
form .userdetails21 .inputbox21{
    margin-bottom: 15px;
    width: 100%;
  }
  form .category21{
    width: 100%;
  }
  .conttent21 form .userdetails21{
    max-height: 300px;
    overflow-y: scroll;
  }
  .userdetails21::-webkit-scrollbar{
    width: 5px;
  }
  }
  @media(max-width: 459px){
  .container21 .conttent21 .category21{
    flex-direction: column;
  }
}
@media(max-width: 584px){
    .account21{
      grid-template-columns: none;
     
    }
  }
/* @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap'); */
/* *{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
/* body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
} */
.bluelinenew{
    background: rgb(95, 177, 255);
    height: 13vmin;
    margin-top: 109px;
    width: 100vw;
    margin-bottom: 30px;
}
.bluelinenew p{
    /* margin-top: auto; */
    padding: 2.3% 0;
    font-size: 2.2vmin;
    font-weight: 500;
    text-align: center;
    color: white;
}
.contactpage{
    display: flex;
    height: 100vh;
     
  }
  .signupContainer {
    height: calc(100vh - 50px);
    width: 60%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:5px
}
.text{
  /* text-align: center; */
  font-size: 35px;
  font-weight: 600;
  color: #000;
}
  .tea{
    font-size: 2.6vmin;
    color: #444;
    margin-top: 1%;
}
.signupWrapper {
    width: 80%;
    height: 85%;
     
    display: flex;
}

.signupRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signupBox {
    border-radius: 5%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-around;
    box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
    -webkit-box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.45);
}
.divinput{
    display: flex;
     width: 100%;
     position: relative;
      
 }
 .divinput label{
        position: absolute;
        top: 8px;
        left: 10px;
        color: rgb(117 98 98);
        text-transform: capitalize;
        font-size: 18px;
        transition: top .3s;
        padding: 0 5px;
        pointer-events: none;
 }
 .divinput input:focus ~ label, .divinput input:not(:focus):valid ~ label{
        top:-12px;
        background-color:white ;
        font-size: 15px;
        color: #1775ee;
        border-radius: 10px;
}
      
.signupInput {
    height: 40px;
    background: transparent;
    padding-left: 20px;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid grey;
    outline: none;
    width: 100%;
}
.signupInput:focus {
    border: 1px solid blue;
}
 

.signupButton, .loginRegisterButton {
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.signupButton{
    background-color: #283290;
    font-size: 20px;
    font-weight: 500;
    color: white;
}
.signupButton:disabled{
    cursor: not-allowed;
}
 
.contactpage .right{
    
  margin-top: 4rem ;
  margin-left: 30px;
  align-items: center;
  justify-content: center;
}
 .right h2{
   
  font-weight: 600;
  margin-bottom: 40px;
}
.right h3{
   
  font-weight: 400;
  margin-bottom: 10px;
}
.atag{
   margin-bottom: 35px;
 }
@media only screen and (max-width: 768px) {
  .contactpage{
    flex-direction: column;
  }
   


.signupContainer {
  margin:5px auto;
  width:80%
}
.signupWrapper {
  width: 100%;
    
}

.tea{
  font-size: 3.6vmin;
  
}
.contactpage .right{
    
    margin-top: 15px ;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
}
 
.divadd{
  margin-top: 5%;
}
}
.development-cards{
  margin-top: 6%;
  background: none;
}
.section-containerrr
{
margin-bottom: 20vmin;
margin-top: 11%;
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
}
.section-containerrr .columnsss
{
flex:0 0 auto;
width:45%;
}
.section-containerrr .colsss
{
flex:0 0 auto;
width:53%;
}

.section-containerrr .columnsss.imageee
{
background-position:center;
background-repeat:no-repeat;
background-size:cover;
}
.section-containerrr .colsss.imggg
{
background-position:center;
background-repeat:no-repeat;
background-size:cover;
}
/* .section-containerrr .colsss.imggg img{
  margin-right: 10%;
} */










.section-containerrr .columnsss.contenttt .content-containerrr
{
padding:40px 90px;
margin-left: 2%;

}
.section-containerrr .columnsss.contenttt .content-containerrr h6{
  font-size: 2.6vmin;
  color: #444;
  font-weight: 450;

}

.section-containerrr .columnsss.contenttt .content-containerrr h5
{

margin-top: 10%;
font-weight: 650;
font-size: 6.5vmin;
line-height: 8vmin;
font-family: Inter;
color: #222;
}
.section-containerrr .columnsss.contenttt .content-containerrr p
{

font-weight: 400;
padding: 2% 0;
font-size: 2.4vmin;  
color: rgba(33, 33, 33, 0.6);    

}
.section-containerrr .columnsss.contenttt .content-containerrr a
{
  display: inline-block;
  background: none;
  color:#FF9C33;
  font-size: 2.4vmin;
  font-weight: 600;
  padding: 2vmin 5%;
  margin-top: 20px;
  border-radius: 6vmin;
  border: 2px solid #FF8300;
  transition: all 0.3s ease;
}

@media screen and (max-width:767px)
{
.section-containerrr
{
flex-flow:row wrap;
margin-top: 40%;
}
.section-containerrr .columnsss.imageee{
display:block;
order:1;
width:100%;
height:250px;

}
.section-containerrr .colsss.imggg{
  display:block;
  order:1;
  padding: 1rem;
  width:100%;
  }
.section-containerrr .columnsss.contenttt
{
display:block;
order:2;
width:100%;
}
.section-containerrr .columnsss.contenttt .content-containerrr
{
padding:20px 45px;
}
.section-containerrr .columnsss.contenttt .content-containerrr h5
{
margin-bottom:5px;
font-weight: 800;
}
.section-containerrr .columnsss.contenttt .content-containerrr p
{

font-weight: 500;
padding: 2% 0;
font-size: 4.0vmin;  
color: rgba(33, 33, 33, 0.6);    

}
.section-containerrr .columnsss.contenttt .content-containerrr p h6
{

font-weight: 600;
padding: 2% 0;
font-size: 4.4vmin;  
color: rgba(33, 33, 33, 0.6);    

}
}




























.tilt-box-wrap{
	/* width: 600px;
	height: 600px; */
	position: relative;
	transition: all 0.6s ease-out;
	perspective: 1000px
}
.tilt-box-wrap:hover{
	transition: all 0.3s linear;
	transform: scale(1.1);
}
.tilt-box{
	width: 100%;
	height: 100%;
	background: none;
	position: relative;
	display: flex;
	/* align-items: center; */
	justify-content: space-around;
	text-align: center;
	color: #fff;
	font-size: 90px;
	font-weight: 700;
	text-transform: uppercase;
	transition: all 0.6s ease-out;
	transform:rotateX(0deg) rotateY(0deg);
	perspective: 1000px;
	transform-style: preserve-3d;
}
.tilt-box strong{
	transform:translateZ(40px);
}
.t_over{
	width: 33.333%;
	height: 33.333%;
	position: absolute;
	z-index: 1;
}

.t_over:nth-child(1){ left: 0; top: 0; }
.t_over:nth-child(2){ left: 33.333%; top: 0; }
.t_over:nth-child(3){ left: 66.666%; top: 0; }
.t_over:nth-child(4){ left: 0; top: 33.333%; }
.t_over:nth-child(5){ left: 33.333%; top: 33.333%; }
.t_over:nth-child(6){ left: 66.666%; top: 33.333%; }
.t_over:nth-child(7){ left: 0; top: 66.666%; }
.t_over:nth-child(8){ left: 33.333%; top: 66.666%; }
.t_over:nth-child(9){ left: 66.666%; top: 66.666%; }
.t_over:nth-child(1):hover ~ .tilt-box{transform:rotateX(-20deg) rotateY(20deg);}
.t_over:nth-child(2):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(0deg)}
.t_over:nth-child(3):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(-20deg)}
.t_over:nth-child(4):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(20deg)}
.t_over:nth-child(5):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(0deg)}
.t_over:nth-child(6):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(-20deg)}
.t_over:nth-child(7):hover ~ .tilt-box{transform:rotateX(20deg) rotateY(20deg);}
.t_over:nth-child(8):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(0deg)}
.t_over:nth-child(9):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(-20deg)}





.development-cards{
    margin-top: 6%;
    background: none;
  }
  .section-containerrr
  {
  margin-bottom: 20vmin;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  }
  .section-containerrr .columnsss
  {
  flex:0 0 auto;
  width:45%;
  }
  .section-containerrr .colsss
  {
  flex:0 0 auto;
  width:53%;
  }
  
  .section-containerrr .columnsss.imageee
  {
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  }
  .section-containerrr .colsss.imggg
  {
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  }
  /* .section-containerrr .colsss.imggg img{
    margin-right: 10%;
  } */
  
  
  
  
  
  
  
  
  
  
  .section-containerrr .columnsss.contenttt .content-containerrr
  {
  padding:40px 90px;
  margin-left: 2%;
  
  }
  .section-containerrr .columnsss.contenttt .content-containerrr h6{
    font-size: 2.6vmin;
    color: #444;
    font-weight: 450;
  
  }
  
  .section-containerrr .columnsss.contenttt .content-containerrr h5
  {
  
  margin-top: 10%;
  font-weight: 650;
  font-size: 6.5vmin;
  line-height: 8vmin;
  font-family: Inter;
  color: #222;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr p
  {
  
  font-weight: 400;
  padding: 2% 0;
  font-size: 2.4vmin;  
  color: rgba(33, 33, 33, 0.6);    
  
  }
  .section-containerrr .columnsss.contenttt .content-containerrr a
  {
    display: inline-block;
    background: none;
    color:#FF9C33;
    font-size: 2.4vmin;
    font-weight: 600;
    padding: 2vmin 5%;
    margin-top: 20px;
    border-radius: 6vmin;
    border: 2px solid #FF8300;
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width:767px)
  {
  .section-containerrr
  {
  flex-flow:row wrap;
  }
  .section-containerrr .columnsss.imageee{
  display:block;
  order:1;
  width:100%;
  height:250px;
  
  }
  .section-containerrr .colsss.imggg{
    display:block;
    order:1;
    padding: 1rem;
    width:100%;
    }
  .section-containerrr .columnsss.contenttt
  {
  display:block;
  order:2;
  width:100%;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr
  {
  padding:20px 45px;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr h5
  {
  margin-bottom:5px;
  }
  
   
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .tilt-box-wrap{
      /* width: 600px;
      height: 600px; */
      position: relative;
      transition: all 0.6s ease-out;
      perspective: 1000px
  }
  .tilt-box-wrap:hover{
      transition: all 0.3s linear;
      transform: scale(1.1);
  }
  .tilt-box{
      width: 100%;
      height: 100%;
      background: none;
      position: relative;
      display: flex;
      /* align-items: center; */
      justify-content: space-around;
      text-align: center;
      color: #fff;
      font-size: 90px;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.6s ease-out;
      transform:rotateX(0deg) rotateY(0deg);
      perspective: 1000px;
      transform-style: preserve-3d;
  }
  .tilt-box strong{
      transform:translateZ(40px);
  }
  .t_over{
      width: 33.333%;
      height: 33.333%;
      position: absolute;
      z-index: 1;
  }
  
  .t_over:nth-child(1){ left: 0; top: 0; }
  .t_over:nth-child(2){ left: 33.333%; top: 0; }
  .t_over:nth-child(3){ left: 66.666%; top: 0; }
  .t_over:nth-child(4){ left: 0; top: 33.333%; }
  .t_over:nth-child(5){ left: 33.333%; top: 33.333%; }
  .t_over:nth-child(6){ left: 66.666%; top: 33.333%; }
  .t_over:nth-child(7){ left: 0; top: 66.666%; }
  .t_over:nth-child(8){ left: 33.333%; top: 66.666%; }
  .t_over:nth-child(9){ left: 66.666%; top: 66.666%; }
  .t_over:nth-child(1):hover ~ .tilt-box{transform:rotateX(-20deg) rotateY(20deg);}
  .t_over:nth-child(2):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(0deg)}
  .t_over:nth-child(3):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(-20deg)}
  .t_over:nth-child(4):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(20deg)}
  .t_over:nth-child(5):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(0deg)}
  .t_over:nth-child(6):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(-20deg)}
  .t_over:nth-child(7):hover ~ .tilt-box{transform:rotateX(20deg) rotateY(20deg);}
  .t_over:nth-child(8):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(0deg)}
  .t_over:nth-child(9):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(-20deg)}
  
  
  
  
  
.development-cards{
    margin-top: 6%;
    background: none;
  }
  .section-containerrr
  {
  margin-bottom: 20vmin;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  }
  .section-containerrr .columnsss
  {
  flex:0 0 auto;
  width:45%;
  }
  .section-containerrr .colsss
  {
  flex:0 0 auto;
  width:53%;
  }
  
  .section-containerrr .columnsss.imageee
  {
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  }
  .section-containerrr .colsss.imggg
  {
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  }
  /* .section-containerrr .colsss.imggg img{
    margin-right: 10%;
  } */
  
  
  
  
  
  
  
  
  
  
  .section-containerrr .columnsss.contenttt .content-containerrr
  {
  padding:40px 90px;
  margin-left: 2%;
  margin-top: -19%;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr h6{
    font-size: 2.6vmin;
    color: #444;
    font-weight: 450;
  
  }
  
  .section-containerrr .columnsss.contenttt .content-containerrr h5
  {
  
  margin-top: 10%;
  font-weight: 650;
  font-size: 6.5vmin;
  line-height: 8vmin;
  font-family: Inter;
  color: #222;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr p
  {
  
  font-weight: 400;
  padding: 2% 0;
  font-size: 2.4vmin;  
  color: rgba(33, 33, 33, 0.6);    
  
  }
  .section-containerrr .columnsss.contenttt .content-containerrr a
  {
    display: inline-block;
    background: none;
    color:#FF9C33;
    font-size: 2.4vmin;
    font-weight: 600;
    padding: 2vmin 5%;
    margin-top: 20px;
    border-radius: 6vmin;
    border: 2px solid #FF8300;
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width:767px)
  {
  .section-containerrr
  {
  flex-flow:row wrap;
  margin-top: 25%;
  }
  .section-containerrr .columnsss.imageee{
  display:block;
  order:1;
  width:100%;
  height:250px;
  
  }
  .section-containerrr .colsss.imggg{
    display:block;
    order:1;
    padding: 1rem;
    width:100%;
    }
  .section-containerrr .columnsss.contenttt
  {
  display:block;
  order:2;
  width:100%;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr
  {
  padding:20px 45px;
  }
  .section-containerrr .columnsss.contenttt .content-containerrr h5
  {
  margin-bottom:5px;
  }
  
   
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .tilt-box-wrap{
      /* width: 600px;
      height: 600px; */
      position: relative;
      transition: all 0.6s ease-out;
      perspective: 1000px
  }
  .tilt-box-wrap:hover{
      transition: all 0.3s linear;
      transform: scale(1.1);
  }
  .tilt-box{
      width: 100%;
      height: 100%;
      background: none;
      position: relative;
      display: flex;
      /* align-items: center; */
      justify-content: space-around;
      text-align: center;
      color: #fff;
      font-size: 90px;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.6s ease-out;
      transform:rotateX(0deg) rotateY(0deg);
      perspective: 1000px;
      transform-style: preserve-3d;
  }
  .tilt-box strong{
      transform:translateZ(40px);
  }
  .t_over{
      width: 33.333%;
      height: 33.333%;
      position: absolute;
      z-index: 1;
  }
  
  .t_over:nth-child(1){ left: 0; top: 0; }
  .t_over:nth-child(2){ left: 33.333%; top: 0; }
  .t_over:nth-child(3){ left: 66.666%; top: 0; }
  .t_over:nth-child(4){ left: 0; top: 33.333%; }
  .t_over:nth-child(5){ left: 33.333%; top: 33.333%; }
  .t_over:nth-child(6){ left: 66.666%; top: 33.333%; }
  .t_over:nth-child(7){ left: 0; top: 66.666%; }
  .t_over:nth-child(8){ left: 33.333%; top: 66.666%; }
  .t_over:nth-child(9){ left: 66.666%; top: 66.666%; }
  .t_over:nth-child(1):hover ~ .tilt-box{transform:rotateX(-20deg) rotateY(20deg);}
  .t_over:nth-child(2):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(0deg)}
  .t_over:nth-child(3):hover ~ .tilt-box{transform: rotateX(-20deg) rotateY(-20deg)}
  .t_over:nth-child(4):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(20deg)}
  .t_over:nth-child(5):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(0deg)}
  .t_over:nth-child(6):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(-20deg)}
  .t_over:nth-child(7):hover ~ .tilt-box{transform:rotateX(20deg) rotateY(20deg);}
  .t_over:nth-child(8):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(0deg)}
  .t_over:nth-child(9):hover ~ .tilt-box{transform: rotateX(20deg) rotateY(-20deg)}
  
  
  
  
  
.floating-btn{
    width: 55px;
    height: 55px;
    background-color: #009879;
    display: flex;
    border-radius: 50%;
    color: white;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1111;
  }

  .textIc{
      color: white;
  }
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  max-width: 100vw !important;
  font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: white;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(18, 18, 88);
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

ul{
  text-decoration: none;
}

li{
  text-decoration: none;
}

a{
  text-decoration: none;
}
