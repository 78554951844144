body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}
.contai {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
:root {
  --primary: hsla(192, 100%, 78%, 0.65);
  --secondary: hsla(192, 100%, 78%, 0.65);
}
.contai .box,
.box1,
.box2 {
  position: relative;
  width: 350px;
  padding: 40px;
  /* background: rgba(255, 255, 255, 0.151); */
  /* background-image: url('./hotel.jpeg'); */
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  box-sizing: border-box;
  overflow: hidden;
  /* background: linear-gradient(135deg, var(--primary), var(--secondary)),
    url("./HOTEL.jpg") no-repeat center / cover; */
}

.contai .box {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./residential.jpg") no-repeat center / cover;
}
.contai .box1 {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./Commercial.jpg") no-repeat center / cover;
}
.contai .box2 {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./HOTEL.jpg") no-repeat center / cover;
}
.contai h3 {
  font-size: 6vmin;
  text-align: center;
  color: #90e0ef;
  font-weight: 900;
  margin: 10px 0;
  padding: 0;
}
.contai p {
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 0;
}

.contai a {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  background: #90e0ef;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
}
.contai a:hover {
  color: #fff;
  border: 2px solid #90e0ef;
  background: none;
  cursor: pointer;
}

.h1211 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1% auto;
}

.indushead {
  color: #8cb649;
  font-size: 42px;
}
@media screen and (max-width: 430px) {
  .jod {
    margin-top: 203px;
    text-align: center;
  }
  .jod p {
    font-size: 16px;
    display: block;
  }
  .jod h2 {
    font-size: 34px;
  }
  .contai .box,
  .box1,
  .box2 {
    margin: 32px;
  }
  .indushead {
    font-size: 30px;
    text-align: center;
  }
}
