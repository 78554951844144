.scrollToTop {
    background-color: rgb(18, 18, 88);
    border-radius: 100%;
    position: fixed;
    height: 4rem;
    width: 4rem;
    bottom: 80px;
    right: 40px;
    z-index: 2000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: white;
      font-size: 2rem;
    }
    &:hover {
      background-color: white;
      svg {
        color: rgb(18, 18, 88);
      }
    }
  }
  