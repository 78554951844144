@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}
.account112{
    display: grid;
    grid-template-columns: 20% auto;
    height: 100%;
    margin-top: 80px;
  }
  .account112 .left12{
    background-color: blue;
    text-align: center;
    align-items: center;
    padding: 10% 0%;
  
  }
  .account112 .left12 h3{
    margin-top: 10%;
    font-size: 5vmin;
    color: white;
    
  }
  .account112 .left12 a{
    display: inline-block;
  background: rgb(255, 255, 255);
  font-size: 3vmin;
  color: black;
  cursor: pointer;
  width: 34vmin;
  box-shadow: 0 1px 3px 1px #888888;
  text-align: center;
  padding: 3.5% 0;
  margin-top: 8%;
  margin-left: 10px;
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease;
  }
.profilebody112{
  height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.container112{
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}
.container112 .titlee112{
  font-size: 25px;
  font-weight: 500;
  position: relative;
}
.container112 .titlee112::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
.conttent112 form .userdetailss112{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
form .userdetailss112 .inputboxl112{
  margin-bottom: 15px;
  width: 100%;
}
form .inputboxl112 span.details112{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.userdetailss112 .inputboxl112 input{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.userdetailss112 .inputboxl112 input:focus,
.userdetailss112 .inputboxl112 input:valid{
  border-color: #9b59b6;
}
 form .genderdetails112 .gendertitlee112{
  font-size: 20px;
  font-weight: 500;
 }
 form .category{
   display: flex;
   width: 80%;
   margin: 14px 0 ;
   justify-content: space-between;
 }
 form .category label{
   display: flex;
   align-items: center;
   cursor: pointer;
 }
 form .category label .dot{
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 10px;
  background: #d9d9d9;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}
 #dot-1:checked ~ .category label .one,
 #dot-2:checked ~ .category label .two,
 #dot-3:checked ~ .category label .three{
   background: #9b59b6;
   border-color: #d9d9d9;
 }
 form input[type="radio"]{
   display: none;
 }
 form .button112{
   height: 45px;
   margin: 35px 0
 }
 form .button112 input{
   height: 100%;
   width: 100%;
   border-radius: 5px;
   border: none;
   color: #fff;
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 1px;
   cursor: pointer;
   transition: all 0.3s ease;
   background: linear-gradient(135deg, #71b7e6, #9b59b6);
 }
 form .button112 input:hover{
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
  }
 @media(max-width: 584px){
 .container112{
  max-width: 100%;
}
form .userdetailss112 .inputboxl112{
    margin-bottom: 15px;
    width: 100%;
  }
  form .category{
    width: 100%;
  }
  .conttent112 form .userdetailss112{
    max-height: 300px;
    overflow-y: scroll;
  }
  .userdetailss112::-webkit-scrollbar{
    width: 5px;
  }
  }
  @media(max-width: 459px){
  .container112 .conttent112 .category{
    flex-direction: column;
  }
}
@media(max-width: 584px){
    .account112{
      grid-template-columns: none;
     
    }
  }