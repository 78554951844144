.floating-btn{
    width: 55px;
    height: 55px;
    background-color: #009879;
    display: flex;
    border-radius: 50%;
    color: white;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1111;
  }

  .textIc{
      color: white;
  }