.addtocartpage{
    display: flex;
    justify-content: center;
    background-color: #f0f2f5;
    height: calc(100vh - 50px);
}
.addtocartpageWrapper{
    margin: 20px 0px;
    width: 80%;
}
.footer{
    float: right;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
}
.gt{
    margin-right: 50px;
}
 
.storeregInput {
    background: transparent;
    border-radius: 10px;
    padding-left: 3%;
    border: 1px solid grey;
    outline: none;
     height: 30px;
}
.storeregInput:focus {
    border: 1px solid blue;
}

 
.ckButton{
    background-color: #42b72a;
    margin: 20px;
    font-size: 15px;
    font-weight: 500;
    color: white;
    height: 40px;
    padding: 10px 50px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.idTest{
    height: 10px;
    font-size: 17px;
    padding: 10px;
}
.idButton{
    height: 30px;
    width: 30px;
    background-color: red;
    color: white;
    border: none;
    outline: none;
    font-size: 25px;
    cursor: pointer;
}
 .col-3{
     width: 180px !important;
 }




/* cart item design */
.cartitem{
    margin: 10px 2px;
    background-color: white;
}
.cartitemWrapper{
    padding: 10px;
    display: flex;
}

.cartHeader{
    display: flex;
}
.chleft{
    flex: 0.7;
    display: flex;
    flex-direction: row;
}
.removeCart{
    cursor: pointer;
}
.secDiv{
    flex: 0.3;
    display: flex;
}
.chmid{
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chright{
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartItemSpinner{
    padding: 8px;
}

.cartHeaderImg{
    height: 120px;
    width: 130px;
}
.headerText{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}
.quantityDiv{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}
.headerText h4{
    margin: 4px 0px;
    padding: 0;
}
.ciPrice{
    font-weight: 500;
    font-size: 18px;
}
.removeCart{
    color: red;
}
.row1{
    margin: 10px auto;
}
.btn-primary{
    color : white !important
}

.addzip{
    margin-top: 4%;
}
.modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3.3rem !important;
    outline: 0;
}
/* .modal11{
    border-radius: 10% !important;
     
} */

.contBtn{
    width: 50%;
    margin: auto;
}
@media only screen and (max-width: 650px) {
    .addtocartpageWrapper{
        margin: 10px 0px;
        width: 95%;
        height: calc(100vh - 50px);
    }
    .cartitemWrapper{
        flex-direction: column;
    }
    
    .row1{
        flex-direction: column;
    }
}