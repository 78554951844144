.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: (0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 440px;
  background-color: rgb(95, 207, 95);
  border-radius: 30px;
  // margin-top: 3%;
}
.popup-inner:hover {
  background-color: rgb(71, 250, 71);
  border-radius: 10px;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 10px;
  width: 8%;
}
.popup-inner .close-btn:hover {
  background-color: rgb(245, 79, 79);
}
.popup-inner h3 {
  font-weight: 700;
}
.popup-inner p {
  font-weight: 600;
}

.popWrap {
  display: flex;
  margin-top: 4%;
}

.pop1 {
  margin-right: 3%;
}
@media screen and (max-width: 430px) {
  .popup {
    width: 94%;
    height: 100vh;
    display: block;
    // margin:4%;
    margin: 3%;
    margin-top: 20%;
    transform-style: preserve-3d;
  }
}
