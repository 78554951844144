.p9 {
    border     : 1px solid #9F9B97;
    padding    : 3vw 3vw 3vw 3vw;
    color      : #8A7A77;
    font-weight: 500;
    font-size  : 1.1vw;
    margin     : 5vw 10vw -2vw 10vw;
    transition : all 0.5s ease;
}

@media screen and (max-width: 430px) {
    .p9 {
        display        : flex;
        align-items    : center;
        justify-content: center;
        border         : 1px solid #9F9B97;
        padding        : 3vw 3vw 3vw 3vw;
        color          : #8A7A77;
        font-weight    : 500;
        font-size      : 3.5vw;
        margin         : 5vw 5vw 5vw 5vw;
    }
}